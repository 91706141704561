import { AUTH_TYPES } from "../types";
import router from "../../router";
import { getCookie, removeCookie, setCookie } from "../../common/cookie";
import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
const state = {
    user:{fullname:''},
};

const getters = {
    [AUTH_TYPES.GET_USER](state) {
        return state.user
    },
    [AUTH_TYPES.IS_LOGGED_IN]() {
        return getCookie("logged_in");
    },
};

const actions = {
    async [AUTH_TYPES.LOGIN]({state}, { user }) {
        let expiringDays = 1;
        if (user.stay_logged_in) {
            expiringDays = 365;
        }
        const response = await ApiService.getCookies().then(() => {
            return ApiService.post("login", user);
        });
        // TODO enablen if backend connected
        if (getData(response, "status") === 200) {
            const userResponse = await ApiService.query("user")
            state.user = getData(userResponse,'data').data
        console.log(getData(response, "status"));
        setCookie("logged_in", true, expiringDays);
        router.push({ name: "Dashboard" });
        }
        return;
    },
    async [AUTH_TYPES.FETCH_USER]({state}){
        const userResponse = await ApiService.query("user")
            state.user = getData(userResponse,'data').data;
    },
    async [AUTH_TYPES.UPDATE_LOGGED_IN_USER]({dispatch}, {inputUser}) {
        const response = await ApiService.updateSingle("user", inputUser);
        dispatch(AUTH_TYPES.FETCH_USER);
        return response;
    },
    async [AUTH_TYPES.REQUEST_PASSWORD_EMAIL](_1, { user }) {
        console.log("Send Mail");
        const test = {email: user.email, enter_pw_url: 'https://app.spaleck.de:8080/enternewpassword'}
        const response = await ApiService.post("resetPassword",test)
        return response
    },
    async [AUTH_TYPES.RESET_PASSWORD](_1, { user }) {
        console.log("reset password");
        const response = await ApiService.post("resetPasswordCallback",user)
        if(response.status === 200){
        setTimeout(()=>{router.push({ name: "Dashboard" });},5000)
        }
        return response;
        
    },
    async [AUTH_TYPES.SEND_2FA_CODE]({ state }, { twoFactorItem }) {
        console.log("send code");
        console.log(twoFactorItem);
        console.log(state);
    },
    async [AUTH_TYPES.RESEND_2FA_CODE]() {
        console.log("resend code");
    },
    async [AUTH_TYPES.LOGOUT]({state}) {
        if (getCookie("logged_in")) {
            removeCookie("logged_in");
            ApiService.post("logout");
            state.user = {}
        }
        router.push({ name: "Login" });
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
