<template>
  <div
    class="avatar relative top flex justify-center items-center rounded-full"
    :style="borderColor ? 'border-color: ' + borderColor : 'border-width:0px'"
    :class="`avatar--${size} ${
      image ==  imageURL || image == null
        ? 'bg-primary'
        : 'bg-transparent'
    }`"
  >
    <h3
      v-if="image == imageURL || image == null"
      class="text text-white"
      :class="`text--${size}`"
    >
      {{ acronym }}
    </h3>
    <img v-else :src="image" alt="" class="rounded-full" />
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { ENV } from "../../../env";
import makeAcronym from "../../common/acronym";
export default defineComponent({
  name: "AvatarBubble",
  props: {
    name: {
      type: String,
      required: true,
    },
    borderColor: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "medium",
    },
    image: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    /**
     * Computed
     */
    const imageURL = computed(() => {
      return ENV.IMAGE_URL
    });
    const acronym = computed(() => {
      return makeAcronym(props.name);
    });
    return {
      // Computed
      acronym,
      imageURL,
    };
  },
});
</script>

<style lang="scss">
.avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-width: 0.15rem;
  &.avatar--big {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
  }
  &.avatar--xl {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
  }
  &.avatar--sm {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}
.text {
  font-size: 16px;
  font-weight: 500;
  &.text--big {
    font-size: 18px;
  }
  &.text--xl {
    font-size: 25px;
  }
}
</style>
