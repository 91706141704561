<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="438.145" height="75.237" viewBox="0 0 438.145 75.237">
  <g id="Page-1" transform="translate(1113.103 400.496)">
    <g id="spaleck_logo_rz_20170904_1" transform="translate(-1113.103 -400.496)">
      <path id="Fill-1" d="M58.992,23.492V8.361H21.163a20.537,20.537,0,0,0,0,41.071l17.264,0a5.454,5.454,0,0,1-.009,10.908H.9V75.474h37.83A20.539,20.539,0,0,0,38.732,34.4H21.468a5.454,5.454,0,0,1,.008-10.908Z" transform="translate(-0.897 -1.394)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-2" d="M404.649,15.653a34.687,34.687,0,1,0-.021,52.318L394.555,53.586a17.306,17.306,0,1,1,.02-23.545l10.074-14.388" transform="translate(-40.114 -1.253)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-3" d="M133.727,30.7c0-12.175-8.251-22.338-23.344-22.338H76.777V75.474H94.083V52.935h16.3C125.476,52.935,133.727,42.773,133.727,30.7Zm-17.608,0c0,4.528-3.421,7.144-8.05,7.144H94.083V23.454h13.986c4.629,0,8.05,2.616,8.05,7.245Z" transform="translate(-9.49 -1.394)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-4" d="M266.71,75.474V60.381H239.743V8.361H222.437V75.474H266.71" transform="translate(-25.984 -1.394)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-5" d="M331.587,75.474V60.381h-31.9V49.431h31.192V34.358H299.691v-10.9h31.9V8.361h-49.2V75.474h49.2" transform="translate(-32.772 -1.394)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-6" d="M433.871,41.918l23.5-33.557H435.474l-23.5,33.557,23.5,33.556h21.892l-23.5-33.556" transform="translate(-47.447 -1.394)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-7" d="M486.245,14.439a6.968,6.968,0,1,1,6.967-6.968A6.971,6.971,0,0,1,486.245,14.439Zm0-12.785a5.817,5.817,0,1,0,5.817,5.817A5.786,5.786,0,0,0,486.245,1.654Zm1.873,9.827-2.07-3.155h-1.282v3.155h-1.217V3.494H486.8A2.57,2.57,0,0,1,489.5,5.927a2.337,2.337,0,0,1-2.038,2.367l2.136,3.188ZM486.8,4.513h-2.038V7.274H486.8a1.416,1.416,0,0,0,1.446-1.348A1.428,1.428,0,0,0,486.8,4.513Z" transform="translate(-55.068 -0.504)" fill="#00a3da" fill-rule="evenodd"/>
      <path id="Fill-9" d="M205.78,75.474,180.424,8.361H163.687L181.3,60.381H157.822l8.9-26.045H148.759L133.435,75.474H205.78" transform="translate(-15.905 -1.394)" fill="#00a3da" fill-rule="evenodd"/>
    </g>
  </g>
</svg>

</template>