<template>
    <svg
        id="CheckOnly"
        xmlns="http://www.w3.org/2000/svg"
        width="13.701"
        height="10.63"
        viewBox="0 0 13.701 10.63"
    >
        <path
            id="Pfad_305"
            data-name="Pfad 305"
            d="M146.559,51.547a.857.857,0,0,1-.608-.257l-4.174-4.215a.959.959,0,0,1-.026-1.307.85.85,0,0,1,1.242-.026l3.53,3.566,7.163-8.1a.848.848,0,0,1,1.24-.047.956.956,0,0,1,.044,1.306L147.2,51.253a.856.856,0,0,1-.618.293Z"
            transform="translate(-141.506 -40.917)"
            :class="fillClass + ' transition-fill-opacity'"
        />
    </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "CheckIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>