<template>
    <div
        class="h-screen w-screen relative bg-black bg-opacity-30"
        style="z-index: 105"
        @click.self="$emit('disagree')"
    >
        <div
            class="absolute top-1/3 left-1/3 ml-44 bg-white rounded-xl h-80 w-96"
            style="z-index: 110"
        >
            <div class="flex justify-center mt-5">
                <p class="font-semibold">Aufwand verbuchen</p>
            </div>
            <div class="flex flex-row ml-2 mr-2 justify-center">
                <input-text
                    :name="'date'"
                    :id="'date'"
                    v-model="state.input.date"
                    :label="'Datum'"
                    :labelClass="'bg-white'"
                    :type="'date'"
                    class="mr-5 mt-3.5 w-52"
                />

                <div class="flex">
                    <input-text
                        :name="'hours'"
                        :id="'hours'"
                        v-model.number="state.hours"
                        :label="'Std.'"
                        :labelClass="'bg-white'"
                        :type="'number'"
                        :lifted="true"
                        class="mt-3.5 w-16 mr-2"
                    />
                    <input-text
                        :name="'minutes'"
                        :id="'minutes'"
                        v-model.number="state.minutes"
                        :label="'Min.'"
                        :labelClass="'bg-white'"
                        :type="'number'"
                        :lifted="true"
                        class="mt-3.5 w-16"
                    />
                </div>
            </div>
            <div class="flex justify-center">
                <input-select
                    :name="'project'"
                    :id="'project'"
                    :options="dropdownProjects.content"
                    :activeItems="[state.project_display]"
                    :defaultColor="'grey-light'"
                    :labelClass="'bg-white text-grey'"
                    :activeColor="'grey'"
                    :label="'Auftragsnummer, Kunde, Teilenummer'"
                    :background="'bg-white'"
                    :closeOnSelect="true"
                    class="mt-3.5 w-96 ml-2 mr-2"
                    @changeItem="
                        (option, index) => {
                            if (state.project_display == option) {
                                setInput(null, index);
                            } else {
                                setInput(option, index);
                            }
                            state.chosenMilestone = null;
                        }
                    "
                ></input-select>
            </div>
            <div class="flex justify-center">
                <input-select
                    :name="'project'"
                    :id="'project'"
                    :options="state.milestones"
                    :activeItems="[state.chosenMilestone]"
                    :defaultColor="'grey-light'"
                    :labelClass="'bg-white text-grey'"
                    :activeColor="'grey'"
                    :label="'Meilenstein'"
                    :background="'bg-white'"
                    :closeOnSelect="true"
                    :inputDisabled="!state.project_display"
                    class="mt-3.5 w-96 ml-2 mr-2"
                    @changeItem="
                        (option) => {
                            state.chosenMilestone = option;
                            state.input.project.milestone = option;
                        }
                    "
                ></input-select>
            </div>

            <div class="flex flex-row justify-between mt-5 ml-3 mr-3">
                <custom-button
                    :id="'3'"
                    :text="'Abbrechen'"
                    :bgColor="'bg-white'"
                    :textColor="'text-primary'"
                    class="w-44 h-11 border-primary border-2 rounded-xl"
                    @click="$emit('disagree')"
                ></custom-button
                ><custom-button
                    :id="'3'"
                    :text="'Aufwand verbuchen'"
                    :bgColor="canBook ? 'bg-primary' : 'bg-grey-light'"
                    :textColor="'text-white'"
                    class="w-44 h-11"
                    @click="canBook ? ($emit('agree'), UPDATE_PROJECT()) : ''"
                ></custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, watch } from "vue";
import InputText from "../../../components/input/InputText.vue";
import InputSelect from "../../../components/input/InputSelect.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import { useStore } from "vuex";
import {
    HELPERS_TYPES,
    PROJECT_TYPES,
    STORE_TYPES,
} from "../../../store/types";
import { cloneData } from "../../../common/cloneData";

export default defineComponent({
    components: {
        InputText,
        InputSelect,
        CustomButton,
    },
    props: { time: { type: Number } },
    emits: ["agree", "disagree"],
    setup(props) {
        const store = useStore();
        const state = reactive({
            input: {},
            projects: [],
            project_numbers: [],
            project_numbers_show: [],
            full_projects: [],
            project_display: null,
            project_partnumbers: [],
            chosenIndex: null,
            chosenMilestone: [],
            milestones: [],
            hours: 0,
            minutes: 0,
        });
        watch(
            () => props.time,
            () => {
                var minutes = Math.floor(props.time % 60);
                var hours = Math.floor(props.time / 60);
                if (hours != 0) {
                    state.hours = hours;
                } else {
                    state.hours = "";
                }
                state.minutes = minutes;
            }
        );
        const projects = computed(() => {
            return store.getters[
                `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.GET_WORKLOAD_OPTIONS}`
            ];
        });

        
        const canBook = computed(() => {
            let canBook = true;
            if((state.minutes == "" && state.hours == "") || state.input.project == null || state.input.date == null || state.chosenMilestone == null)
            {
                canBook = false;
            }
            return canBook;
        });

        const dropdownProjects = computed(() => {
            var projects =
                store.getters[
                    `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.GET_WORKLOAD_OPTIONS}`
                ];
            var project_numbers_show = { content: [], partnumbers: [] };
            if (projects) {
                projects.forEach((project) => {
                    let projectnumber = cloneData(project.projectnumber);

                    let partnumber = cloneData(project.partnumber);

                    if (project.customer != null) {
                        let customer = cloneData(project.customer);
                        if (project.customer) {
                            project_numbers_show.content.push(
                                projectnumber +
                                    ", " +
                                    customer +
                                    ", " +
                                    partnumber
                            );
                        }
                    } else {
                        project_numbers_show.content.push(
                            projectnumber + ", - ," + partnumber
                        );
                    }
                    project_numbers_show.partnumbers.push(project.partnumber);
                });
            }
            return project_numbers_show;
        });

        /**
         * Functions
         */

        function clear() {
            state.input = {};
            state.milestones = [];
            state.project_display = null;
            state.chosenMilestone = null;
            state.hours = 0;
            state.minutes = 0;
            setCurrentDate();
        }

        // async function FETCH_PROJECTS() {
        //   state.projects = [];
        //   state.project_numbers_show = [];
        //   await store.dispatch(
        //     `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.FETCH_PROJECTS}`
        //   );
        // }

        async function setInput(option, index) {
            var tempProjects = await store.getters[
                `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.GET_PROJECTS}`
            ];

            if (option == null) {
                state.milestones = [];
                state.project_display = null;
                return;
            }

            tempProjects.forEach((project) => {
                if (
                    project.partnumber ==
                    dropdownProjects.value.partnumbers[index]
                ) {
                    state.input.project = cloneData(project);
                }
            });
            console.log(projects.value[index]);
            state.milestones = projects.value[index].milestones;
            state.milestones.forEach((milestone,index) => {
                if(milestone == "pruefung_drucken")
                {
                    state.milestones[index] = "Prüfung/Drucken"
                }
            });
            state.project_display = option;
        }

        async function UPDATE_PROJECT() {
            var input = {};
            input.id = state.input.project.id;
            if (state.minutes == null) {
                state.minutes = 0;
            }
            if (!state.hours) {
                state.input.time = state.minutes;
            } else {
                state.input.time = state.hours * 60 + state.minutes;
            }
            input.time = state.input.time * 60000;
            input.date = state.input.date;
            input.milestone = state.chosenMilestone;
            if(input.milestone == "Prüfung/Drucken")
            {
                input.milestone = "pruefung_drucken"
            }
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.CLEAR_ERROR_IDS}`
            );
            await store.dispatch(
                `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.CREATE_PROJECT_TIMELOG}`,
                {
                    inputTimeLog: input,
                }
            );
            store.dispatch(
                `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.FETCH_PROJECTS}`
            );

            clear();
        }
        function setCurrentDate() {
            var date = new Date();
            var currentDay = date.getDate();
            var currentMonth = date.getMonth() + 1;
            var currenctYear = date.getFullYear();

            if (currentDay < 10) {
                currentDay = "0" + currentDay;
            }
            if (currentMonth < 10) {
                currentMonth = "0" + currentMonth;
            }
            state.input.date =
                currenctYear + "-" + currentMonth + "-" + currentDay;
        }

        /**
         *  Computed
         */
        // const GET_USER = computed(
        //   () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
        // );

        onMounted(() => {
            // if(GET_USER.value.fullname != "")
            // {
            setCurrentDate();
            let time = localStorage.getItem("timer")
            if(time != null)
            {
            var minutes = Math.floor(time % 60);
                var hours = Math.floor(time / 60);
                if (hours != 0) {
                    state.hours = hours;
                } else {
                    state.hours = 0;
                }
                state.minutes = minutes;
            }
        });

        return {
            state,
            setInput,
            UPDATE_PROJECT,
            dropdownProjects,
            projects,
            canBook,
        };
    },
});
</script>
