<template>
  <svg
    id="Calendar"
    xmlns="http://www.w3.org/2000/svg"
    width="20.5"
    height="20.5"
    viewBox="0 0 20.5 20.5"
  >
    <path
      id="Pfad_340"
      data-name="Pfad 340"
      d="M19.184,20.4H1.317A1.319,1.319,0,0,1,0,19.086V3.211A1.318,1.318,0,0,1,1.317,1.895H19.184A1.318,1.318,0,0,1,20.5,3.211V19.086A1.318,1.318,0,0,1,19.184,20.4M1.577,18.826H18.923V3.472H1.577Z"
      transform="translate(0 0.097)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_341"
      data-name="Pfad 341"
      d="M4.788,5.414A.789.789,0,0,1,4,4.626V.788a.788.788,0,1,1,1.577,0V4.626a.789.789,0,0,1-.788.788"
      transform="translate(0.205)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_342"
      data-name="Pfad 342"
      d="M9.788,5.414A.789.789,0,0,1,9,4.626V.788a.788.788,0,0,1,1.577,0V4.626a.789.789,0,0,1-.788.788"
      transform="translate(0.462)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_343"
      data-name="Pfad 343"
      d="M14.788,5.414A.789.789,0,0,1,14,4.626V.788a.788.788,0,0,1,1.577,0V4.626a.789.789,0,0,1-.788.788"
      transform="translate(0.718)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_344"
      data-name="Pfad 344"
      d="M7.128,8.814A1.314,1.314,0,1,1,5.814,7.5,1.315,1.315,0,0,1,7.128,8.814"
      transform="translate(0.231 0.385)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_345"
      data-name="Pfad 345"
      d="M11.128,8.814A1.314,1.314,0,1,1,9.814,7.5a1.315,1.315,0,0,1,1.314,1.314"
      transform="translate(0.436 0.385)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_346"
      data-name="Pfad 346"
      d="M15.128,8.814A1.314,1.314,0,1,1,13.814,7.5a1.315,1.315,0,0,1,1.314,1.314"
      transform="translate(0.641 0.385)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_347"
      data-name="Pfad 347"
      d="M7.128,13.814A1.314,1.314,0,1,1,5.814,12.5a1.315,1.315,0,0,1,1.314,1.314"
      transform="translate(0.231 0.641)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_348"
      data-name="Pfad 348"
      d="M11.128,13.814A1.314,1.314,0,1,1,9.814,12.5a1.315,1.315,0,0,1,1.314,1.314"
      transform="translate(0.436 0.641)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_349"
      data-name="Pfad 349"
      d="M15.128,13.814A1.314,1.314,0,1,1,13.814,12.5a1.315,1.315,0,0,1,1.314,1.314"
      transform="translate(0.641 0.641)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>