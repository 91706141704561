import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import translation from "./common/translation";
import { registerIcons } from "./common/icons";
import "./assets/css/index.css";
import ApiService from "./common/apiService";
import { dragscrollNext } from "vue-dragscroll";

const app = createApp(App);

registerIcons(app);

ApiService.init();

app.use(store)
    .use(router)
    .use(translation)
    .directive('dragscroll', dragscrollNext)
    .mount("#app");

export default app;
