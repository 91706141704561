export const STORE_TYPES = {
    AUTH: "auth",
    HELPERS: "helpers",
    SEARCH: "search",
    SETTINGS: "settings",
    USERS: "users",
    PROJECTS: "projects",
    CALENDAR: "calendar",
};

export const HELPERS_TYPES = {
    /*** GETTERS  ***/
    GET_FILTER_ACTIVE_ROUTE: "GET_FILTER_ACTIVE_ROUTE",
    GET_IS_SIDEBAR_ACTIVE: "GET_IS_SIDEBAR_ACTIVE",
    GET_LOADING_IDS: "GET_LOADING_IDS",
    GET_ERROR_IDS: "GET_ERROR_IDS",
    GET_TOAST_MESSAGE: "GET_TOAST_MESSAGE",
    GET_PROJECT_COUNT: "GET_PROJECT_COUNT",
    GET_COUNT: "GET_COUNT",
    GET_EMPTYFIELDS: 'GET_EMPTYFIELDS',

    /*** MUTATIONS  ***/
    SET_FILTER_ACTIVE_ROUTE: "SET_FILTER_ACTIVE_ROUTE",
    SET_IS_SIDEBAR_ACTIVE: "SET_IS_SIDEBAR_ACTIVE",
    SET_LOADING_IDS: "SET_LOADING_IDS",
    SET_LOADING_ID: "SET_LOADING_ID",
    REMOVE_LOADING_ID: "REMOVE_LOADING_ID",
    SET_ERROR_IDS: "SET_ERROR_IDS",
    SET_ERROR_ID_OBJECT: "SET_ERROR_ID_OBJECT",
    REMOVE_ERROR_ID: "REMOVE_ERROR_ID",
    SET_TOAST_MESSAGE: "SET_TOAST_MESSAGE",
    CLEAR_ERROR_IDS: 'CLEAR_ERROR_IDS',
    SET_PROJECT_COUNT: "SET_PROJECT_COUNT",
    SET_EMPTYFIELDS: 'SET_EMPTYFIELDS',
    /*** ACTIONS  ***/
};

export const AUTH_TYPES = {
    /*** GETTERS  ***/
    GET_USER: "GET_USER",
    IS_LOGGED_IN: "IS_LOGGED_IN",
    /*** MUTATIONS  ***/

    /*** ACTIONS  ***/
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    RESET_PASSWORD: "RESET_PASSWORD",
    RESEND_2FA_CODE: "RESEND_2FA_CODE",
    SEND_2FA_CODE: "SEND_2FA_CODE",
    FETCH_USER: "FETCH_USER",
    UPDATE_LOGGED_IN_USER: 'UPDATE_LOGGED_IN_USER',
    REQUEST_PASSWORD_EMAIL: 'REQUEST_PASSWORD_EMAIL',
};

export const SEARCH_TYPES = {
    /*** GETTERS  ***/
    GET_IS_SEARCH_ACTIVE: "GET_IS_SEARCH_ACTIVE",
    GET_SEARCH: "GET_SEARCH",
    GET_SEARCH_ITEMS: "GET_SEARCH_ITEMS",

    /*** MUTATIONS  ***/
    SET_IS_SEARCH_ACTIVE: "SET_IS_SEARCH_ACTIVE",
    SET_SEARCH: "SET_SEARCH",
    SET_SEARCH_ITEMS: "SET_SEARCH_ITEMS",

    /*** ACTIONS  ***/
};

export const SETTINGS_TYPE = {
    /*** ACTIONS  ***/
    FETCH_SETTINGS: "FETCH_SETTINGS",
};
export const USER_TYPES = {
    /*** GETTERS  ***/
    GET_USERS: "GET_USERS",
    GET_USERS_PLANNING: "GET_USERS_PLANNING",
    GET_USER_PERMISSIONS: "GET_USER_PERMISSIONS",
    /*** MUTATIONS  ***/
    SET_USERS: "SET_USERS",
    SET_USERS_PLANNING: "SET_USERS_PLANNING",
    ADD_USER: "ADD_USER",
    SET_USER_PERMISSIONS: 'SET_USER_PERMISSIONS',
    UPDATE_USER: 'UPDATE_USER',
    /*** ACTIONS  ***/
    FETCH_USER_PERMISSIONS: 'FETCH_USER_PERMISSIONS',
    FETCH_USERS: "FETCH_USERS",
    FETCH_USERS_PLANNING: "FETCH_USERS_PLANNING",
    CREATE_USER: "CREATE_USER",
    DELETE_USER: "DELETE_USER",
    UPDATE_USER_PICTURE: "UPDATE_USER_PICTURE",
    DELETE_USER_PICTURE: "DELETE_USER_PICTURE",
};

export const PROJECT_TYPES = {
    /*** GETTERS  ***/
    GET_PROJECTS: "GET_PROJECTS",
    GET_PROJECT: "GET_PROJECT",
    GET_ARCHIV_PROJECTS: "GET_ARCHIV_PROJECTS",
    GET_TIMER: "GET_TIMER",
    GET_PROJECTS_NOTES: "GET_PROJECT_NOTES",
    GET_PROJECT_DESIGNATIONS: 'GET_PROJECT_DESIGNATIONS',
    GET_PROJECT_CUSTOMERS: 'GET_PROJECT_CUSTOMERS',
    GET_PROJECT_MILESTONE_LOGS: 'GET_PROJECT_MILESTONE_LOGS',
    GET_INPUT: 'GET_INPUT',
    GET_PROJECT_TIMELOG: 'GET_PROJECT_TIMELOG',
    GET_DASHBOARD_DATA: 'GET_DASHBOARD_DATA',
    GET_WORKLOAD_OPTIONS: 'GET_WORKLOAD_OPTIONS',
    /*** MUTATIONS  ***/
    SET_PROJECTS: "SET_PROJECTS",
    SET_PROJECT: "SET_PROJECT",
    SET_DASHBOARD_DATA: "SET_DASHBOARD_DATA",
    SET_ARCHIV_PROJECTS: "SET_ARCHIV_PROJECTS",
    SET_PROJECT_DESIGNATIONS: 'SET_PROJECT_DESIGNATIONS',
    SET_PROJECT_CUSTOMERS: 'SET_PROJECT_CUSTOMERS',
    SET_PROJECT_MILESTONE_LOGS:'SET_PROJECT_MILESTONE_LOGS',
    SET_PROJECTS_NOTES: 'SET_PROJECT_NOTES',
    INCREMENT_TIMER: "INCREMENT_TIMER",
    SET_INPUT: "SET_INPUT",
    CLEAR_INPUT: "CLEAR_INPUT",
    SET_PROJECT_TIMELOG: 'SET_PROJECT_TIMELOG',
    SET_WORKLOAD_OPTIONS: 'SET_WORKLOAD_OPTIONS',
    SET_PROJECT_STATUS: "SET_PROJECT_STATUS",
    /*** ACTIONS  ***/
    FETCH_PROJECTS: 'FETCH_PROJECTS',
    FETCH_PROJECT: 'FETCH_PROJECT',
    REVERT_PROJECT: 'REVERT_PROJECT',
    FETCH_DASHBOARD_DATA: 'FETCH_DASHBOARD_DATA',
    FETCH_ARCHIV_PROJECTS: 'FETCH_ARCHIV_PROJECTS',
    FETCH_PROJECTS_NOTES: 'FETCH_PROJECTS_NOTES',
    CREATE_PROJECT: 'CREATE_PROJECT',
    CREATE_PROJECT_NOTE: 'CREATE_PROJECT_NOTE',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    CHANGE_STATUS: 'CHANGE_STATUS',
    UPDATE_PROJECT_NOTE: 'UPDATE_PROJECT_NOTE',
    DELETE_PROJECT_NOTE: 'DELETE_PROJECT_NOTE',
    FETCH_PROJECT_DESIGNATIONS: 'FETCH_PROJECT_DESIGNATIONS',
    FETCH_PROJECT_CUSTOMERS: 'FETCH_PROJECT_CUSTOMERS',
    FETCH_PROJECT_MILESTONE_LOGS: 'FETCH_PROJECT_MILESTONE_LOGS',
    CREATE_PROJECT_TIMELOG: 'CREATE_PROJECT_TIMELOG',
    FETCH_PROJECT_TIMELOG: 'FETCH_PROJECT_TIMELOG',
    DELETE_PROJECT_TIMELOG: 'DELETE_PROJECT_TIMELOG',
    DELETE_PROJECT_MILESTONE_LOGS: 'DELETE_PROJECT_MILESTONE_LOGS',
    MOVE_PROJECT: 'MOVE_PROJECT',
    FETCH_WORKLOAD_OPTIONS: 'FETCH_WORKLOAD_OPTIONS',
    

};
export const CALENDAR_TYPES = {
    /*** GETTERS  ***/
    GET_CALENDAR_ENTRIES: "GET_CALENDAR_ENTRIES",
    /*** MUTATIONS  ***/
    SET_CALENDAR_ENTRIES: "SET_CALENDAR_ENTRIES",
    /*** ACTIONS  ***/
    FETCH_CALENDAR_ENTRIES: 'FETCH_CALENDAR_ENTRIES',
    UPDATE_CALENDAR_ENTRIE: 'UPDATE_CALENDAR_ENTRIE',
    CREATE_CALENDAR_ENTRIE: 'CREATE_CALENDAR_ENTRIE',
    DELETE_CALENDAR_ENTRIE: 'DELETE_CALENDAR_ENTRIE',
};
