import store from "../store";
import { HELPERS_TYPES, STORE_TYPES } from "../store/types";

export default class ShortcutHelper {

    setGlobalShortcuts() {
        const vorbiddenTagNames = ["INPUT", "TEXTAREA"];

        var map = {}; // You could also use an array
        onkeydown = onkeyup = function(e) {
            e = e || event; // to deal with IE
            map[e.keyCode] = e.type == "keydown";
            /* insert conditional here */
        };

        document.addEventListener("keydown", (event) => {
            if (vorbiddenTagNames.includes(document.activeElement.tagName)) {
                //enable browser search if input focused
                if (
                    (map[17] && event.keyCode === 70) ||
                    (map[70] && event.keyCode === 17)
                ) {
                    event.preventDefault();
                   
                }
                map = {};
                return;
               
            }
            // SIDEBAR
            else if (event.keyCode === 77) {
                store.commit(
                        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE}`,
                        !store.getters[
                            `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
                          ]
                );
                map = {};
                return;
            }

            // ZOOM
            else if (event.keyCode === 86) {
                toggleFullscreen()
                map = {};
                return;
            }
        
        });
        function toggleFullscreen() {
            let doc = document;
        if (
            window.innerWidth == screen.width &&
            window.innerHeight == screen.height
        ) {
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            } else if (doc.webkitExitFullscreen) {
                doc.webkitExitFullscreen();
            }
        } else {
            if (doc.documentElement.requestFullscreen) {
                doc.documentElement.requestFullscreen();
            } else if (doc.documentElement.msRequestFullscreen) {
                doc.documentElement.msRequestFullscreen();
            } else if (doc.documentElement.webkitRequestFullscreen) {
                doc.documentElement.webkitRequestFullscreen();
            }
        }
          }
    }
}
