<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.483"
    height="18.724"
    viewBox="0 0 22.483 18.724"
  >
    <g id="Filter" transform="translate(0 0.001)">
      <path
        id="Filter-2"
        data-name="Filter"
        d="M41.11,112.352v-7.373a.5.5,0,0,0-.114-.32l-7.991-9.7a.5.5,0,0,1,.388-.823H54.868a.5.5,0,0,1,.388.823l-7.991,9.7a.5.5,0,0,0-.115.32v4.227a.5.5,0,0,1-.236.427l-5.034,3.145A.5.5,0,0,1,41.11,112.352Zm-5.6-16.72,7.1,8.628v6.293l3.04-1.9V104.26l7.1-8.628Z"
        transform="translate(-32.889 -94.133)"
        fill="#222"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FilterIcon",
});
</script>
