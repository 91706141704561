<template>
  <div
    class="
      w-70px
      items-center
      justify-center
      h-16
      cursor-pointer
      fixed
      top-0
      left-0
      z-50
      transition-all
      hidden
      lg:flex
      hover:bg-white hover:bg-opacity-5
    "
    @click="SET_IS_SIDEBAR_ACTIVE(!GET_IS_SIDEBAR_ACTIVE)"
  >
    <menu-left-icon
      fillClass="fill-white"
      v-if="GET_IS_SIDEBAR_ACTIVE"
    ></menu-left-icon>
    <menu-right-icon fillClass="fill-primary" v-else></menu-right-icon>
  </div>
  <aside
    class="
      flex
      items-center
      fixed
      left-0
      bottom-0
      w-full
      h-12
      bg-primary
      justify-center
      content-center
      transition-transform
      lg:justify-start
      lg:pt-16
      lg:flex-col
      lg:h-full
      lg:w-70px
    "
    :class="[GET_IS_SIDEBAR_ACTIVE ? '' : 'lg:-translate-x-full lg:transform']"
  >
    <div v-for="link in state.links" :key="link.id">
      <sidebar-link v-if="link.id == 3 ? (checkIfUSERAdmin() ? true : false) : link.id== 4 ? (checkIfCalendarAdmin() ? true : false) : true"  :link="link" />
    </div>
    <sidebar-link class="mt-auto" :link="state.settingsLink"></sidebar-link>
    <div
      class="
        h-12
        w-70px
        flex-col
        items-center
        justify-center
        cursor-pointer
        flex
        lg:hidden
        hover:bg-white hover:bg-opacity-5
      "
      @click="state.isDrawerVisible = !state.isDrawerVisible"
    >
      <menu-icon fillClass="fill-white"></menu-icon>
    </div>
  </aside>

  <bottom-up-modal
    title=""
    :isVisible="state.isDrawerVisible"
    :isDrawer="true"
    @closeModal="state.isDrawerVisible = false"
  ></bottom-up-modal>
</template>

<script>
import { defineComponent, reactive, computed } from "vue";
import { useStore } from "vuex";
import { STORE_TYPES, HELPERS_TYPES, AUTH_TYPES } from "../../store/types";
import SidebarLink from "./SidebarLink.vue";
import { useI18n } from "vue-i18n";
import MenuIcon from "../icons/MenuIcon.vue";
import BottomUpModal from "./BottomUpModal.vue";

export default defineComponent({
  components: { SidebarLink, MenuIcon, BottomUpModal },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    /**
     *  State
     */
    const state = reactive({
      links: [
        {
          id: 1,
          name: i18n.t("dashboard"),
          icon: "HomeIcon",
          link: { name: "Dashboard" },
        },
        {
          id: 2,
          name: i18n.t("projects"),
          icon: "ProjectIcon",
          link: { name: "Projekte" },
        },
        {
          id: 2,
          name: i18n.t("planning"),
          icon: "PlanungIcon",
          link: { name: "Planung" },
        },
        {
          id: 3,
          name: i18n.t("personal"),
          icon: "PersonalIcon",
          link: { name: "Personal" },
        },
        {
          id: 4,
          name: i18n.t("calender"),
          icon: "CalenderIcon",
          link: { name: "Kalender" },
        },
      ],
      settingsLink: {
        id: 99,
        name: i18n.t("settings"),
        icon: "SettingsIcon",
        link: { name: "Settings" },
      },
      isDrawerVisible: false,
    });

    /**
     *  Functions
     */
    function SET_IS_SIDEBAR_ACTIVE(value) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE}`,
        value
      );
    }
    function checkIfUSERAdmin() {
      var user = GET_USER.value;
      var canManage = false;
      if (user.permissions != null) {
        user.permissions.forEach((permission) => {
          if (permission.name == "personnel_management") {
            canManage = true;
          }
        });
        return canManage;
      }
    }
    function checkIfCalendarAdmin() {
      var user = GET_USER.value;
      var canManage = false;
      if (user.permissions != null) {
        user.permissions.forEach((permission) => {
          if (permission.name == "calendar_management") {
            canManage = true;
          }
        });
        return canManage;
      }
    }

    /**
     *  Computed
     */
    const GET_IS_SIDEBAR_ACTIVE = computed(
      () =>
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
        ]
    );

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    /**
     * Created
     */

    return {
      state,
      SET_IS_SIDEBAR_ACTIVE,
      GET_IS_SIDEBAR_ACTIVE,
      checkIfUSERAdmin,
      checkIfCalendarAdmin,
    };
  },
});
</script>
