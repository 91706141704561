import { createStore } from "vuex";
import auth from "./modules/auth";
import helpers from "./modules/helpers";
import search from "./modules/search";
import settings from "./modules/settings";
import users from "./modules/users";
import projects from "./modules/projects";
import calendar from "./modules/calendar";
export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        helpers,
        search,
        settings,
        users,
        projects,
        calendar,
    },
});
