<template>
    <div
          class="absolute flex justify-center top-0 z-50 left-0 bg-opacity-30 bg-black h-screen w-screen rounded-xl"
          style="z-index:100000"
          @click="$emit('disagree')"
        >
          <div class="text-center mt-32 bg-white h-52 w-80 rounded-xl shadow-lg">
            <h2 class="text-primary text-3xl font-semibold mt-3">Achtung!</h2>
            <p class="font-semibold mt-5">
              Wollen Sie den Aufwand
              </p>
              <p class="font-semibold">
              wirklich löschen?
            </p>
            <div class="flex flex-row justify-between mt-5 ml-3 mr-3">
              <custom-button
                :id="'3'"
                :text="'Abbrechen'"
                :bgColor="'bg-white'"
                :textColor="'text-primary'"
                class="w-36 h-11 border-primary border-2 rounded-xl"
                @click="$emit('disagree')"
              ></custom-button
              ><custom-button
                :id="'3'"
                :text="'Löschen'"
                :bgColor="'bg-primary'"
                :textColor="'text-white'"
                class="w-36 h-11"
                @click="$emit('agree')"
              ></custom-button>
            </div>
          </div>
        </div>
</template>
<script>
import CustomButton from "../../../../components/input/CustomButton";
export default {
    components:{CustomButton},
    props:['project'],
    emits:['agree','disagree']
}
</script>