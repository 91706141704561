<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.136"
    height="16"
    viewBox="0 0 23.136 16"
  >
    <g id="Card" transform="translate(23.136 16) rotate(180)">
      <path
        id="Pfad_356"
        data-name="Pfad 356"
        d="M19.858,8.728H14.377a.923.923,0,0,1-.991-.835.923.923,0,0,1,.991-.835h5.482a.923.923,0,0,1,.991.835.923.923,0,0,1-.991.835"
        transform="translate(-1.818 -1.893)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_354"
        data-name="Pfad 354"
        d="M21.764,16H1.37A1.272,1.272,0,0,1,0,14.861V1.138A1.273,1.273,0,0,1,1.37,0H21.764a1.273,1.273,0,0,1,1.372,1.138V14.861A1.273,1.273,0,0,1,21.764,16M1.642,14.636H21.494V1.363H1.642Z"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_357"
        data-name="Pfad 357"
        d="M19.858,13.218H14.377a.923.923,0,0,1-.991-.835.923.923,0,0,1,.991-.835h5.482a.923.923,0,0,1,.991.835.923.923,0,0,1-.991.835"
        transform="translate(-1.818 -2.383)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ListIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>