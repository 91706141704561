import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import { PROJECT_TYPES } from "../types";

const state = {
    projects: [],
    project: {},
    archiv_projects: [],
    timer: [],
    project_notes: [],
    project_timelog: [],
    projects_designations: [],
    projects_customers: [],
    project_milestone_logs: [],
    dashboard: [],
    input: {
        projectnumber: "",
        contractnumber: "",
        customer: "",
        partnumber: "",
        designation: "",
        template: "",
        clerk_name: "",
        responsible_name: "",
        constructor_name: "",
        workload_construction: "",
        workload_management: "",
        begin_date: "",
        customer_drawing_date: "",
        deliver_date: "",
        force: false,
        workload_options: [],
    },
};
const getters = {
    [PROJECT_TYPES.GET_PROJECTS]: (state) => { return state.projects },
    [PROJECT_TYPES.GET_PROJECT]: (state) => { return state.project },
    [PROJECT_TYPES.GET_DASHBOARD_DATA]: (state) => { return state.dashboard },
    [PROJECT_TYPES.GET_ARCHIV_PROJECTS]: (state) => { return state.archiv_projects },
    [PROJECT_TYPES.GET_PROJECT_TIMELOG]: (state) => { return state.project_timelog },
    [PROJECT_TYPES.GET_PROJECTS_NOTES]: (state) => { return state.projects_notes },
    [PROJECT_TYPES.GET_PROJECT_DESIGNATIONS]: (state) => { return state.projects_designations },
    [PROJECT_TYPES.GET_PROJECT_CUSTOMERS]: (state) => { return state.projects_customers },
    [PROJECT_TYPES.GET_PROJECT_MILESTONE_LOGS]: (state) => { return state.project_milestone_logs },
    [PROJECT_TYPES.GET_TIMER]: (state) => { return state.timer },
    [PROJECT_TYPES.GET_INPUT]: (state) => { return state.input },
    [PROJECT_TYPES.GET_WORKLOAD_OPTIONS]: (state) => { return state.workload_options }
};
const actions = {
    async [PROJECT_TYPES.FETCH_PROJECTS]({ commit }) {
        const response = await ApiService.query("projects");

        const projects = getData(response, 'data')
        if (projects) {
            commit(PROJECT_TYPES.SET_PROJECTS, projects)
        }
    },
    
    async [PROJECT_TYPES.FETCH_PROJECT]({ commit }, project) {
        const response = await ApiService.query("projects/"+project);
        console.log(response);
        const projects = getData(response, 'data')
        if (projects) {
            commit(PROJECT_TYPES.SET_PROJECT, projects)
        }
        return response.data
    },
    async [PROJECT_TYPES.FETCH_ARCHIV_PROJECTS]({ commit }) {

        const response = await ApiService.query("archiv/projects");

        const projects = getData(response, 'data')
        if (projects) {
            commit(PROJECT_TYPES.SET_ARCHIV_PROJECTS, projects)
        }
    },
    async [PROJECT_TYPES.FETCH_DASHBOARD_DATA]({ commit }) {

        const response = await ApiService.query("dashboard");

        const data = getData(response, 'data')
        if (data) {
            commit(PROJECT_TYPES.SET_DASHBOARD_DATA, data)
        }
    },
    async [PROJECT_TYPES.CREATE_PROJECT]({ dispatch }, { inputProject }) {
        const response = await ApiService.post("projects", inputProject);
        const project = getData(response, 'data')
        if (project) {
            dispatch(PROJECT_TYPES.FETCH_PROJECTS)
        }
        return project;
    },

    async [PROJECT_TYPES.UPDATE_PROJECT](_1, { inputProject }) {
        const response = await ApiService.update("projects", inputProject.id, inputProject);
        if (response) {
            return response;
        }
    },
    async [PROJECT_TYPES.MOVE_PROJECT](_1, { inputProject }) {
        const response = await ApiService.update("projects", inputProject.id, inputProject);
        if (response) {
            // dispatch(PROJECT_TYPES.FETCH_PROJECTS)
            return response;
        }
    },
    async [PROJECT_TYPES.REVERT_PROJECT](_1, { inputProject }) {
        const response = await ApiService.update("archiv/projects", inputProject.id, inputProject);
        if (response) {
            // dispatch(PROJECT_TYPES.FETCH_PROJECTS)
            return response;
        }
    },

    async [PROJECT_TYPES.CHANGE_STATUS](_1, { inputProject }) {
        const response = await ApiService.post(("projects/" + inputProject.id + "/logs"), inputProject);
        if (response) {
            // commit(PROJECT_TYPES.SET_PROJECT_STATUS, inputProject)
            return response
        }
    },
    async [PROJECT_TYPES.FETCH_PROJECTS_NOTES]({ commit }, { inputProject }) {

        const response = await ApiService.query("projects/" + inputProject.id + "/notes");
        const projects_notes = getData(response, 'data')
        if (projects_notes) {
            commit(PROJECT_TYPES.SET_PROJECTS_NOTES, projects_notes)
        }
    },
    async [PROJECT_TYPES.FETCH_PROJECT_DESIGNATIONS]({ commit }, searchInput) {
        const response = await ApiService.get("projects/designation", searchInput);
        const projects_designations = getData(response, 'data')
        if (projects_designations) {
            commit(PROJECT_TYPES.SET_PROJECT_DESIGNATIONS, projects_designations)
        }
    },
    async [PROJECT_TYPES.FETCH_PROJECT_CUSTOMERS]({ commit }, searchInput) {
        const response = await ApiService.get("customers", searchInput);
        const projects_customers = getData(response, 'data')
        if (projects_customers) {
            commit(PROJECT_TYPES.SET_PROJECT_CUSTOMERS, projects_customers)
        }
    },

    async [PROJECT_TYPES.FETCH_PROJECT_MILESTONE_LOGS]({ commit }, { project }) {
        const response = await ApiService.query("projects/" + project.id + "/milestoneLogs");
        const project_milestone_logs = getData(response, 'data')
        if (project_milestone_logs) {
            commit(PROJECT_TYPES.SET_PROJECT_MILESTONE_LOGS, project_milestone_logs)
        }
    },
    async [PROJECT_TYPES.DELETE_PROJECT_MILESTONE_LOGS]({ dispatch }, { input }) {
        const response = await ApiService.delete("projects/" + input.project.id + "/milestoneLogs/" + input.id, input);
        if (response) {
            dispatch(PROJECT_TYPES.FETCH_PROJECT_MILESTONE_LOGS, {
                project: input.project,
            })
            return response
        }
    },
    async [PROJECT_TYPES.CREATE_PROJECT_NOTE]({ dispatch }, { inputNote }) {
        console.log(inputNote)
        const response = await ApiService.post("projects/" + inputNote.project_id + "/notes", inputNote);
        const project = getData(response, 'data')
        if (project) {
            dispatch(PROJECT_TYPES.FETCH_PROJECTS_NOTES, {
                inputProject: { id: inputNote.project_id },
            })
        }
        return project;
    },
    async [PROJECT_TYPES.UPDATE_PROJECT_NOTE]({ dispatch }, { inputNote }) {
        console.log(inputNote)
        const response = await ApiService.update("projects/" + inputNote.project_id + "/notes", inputNote.id, inputNote);
        const project = getData(response, 'data')
        if (project) {
            dispatch(PROJECT_TYPES.FETCH_PROJECTS_NOTES, {
                inputProject: { id: inputNote.project_id },
            })
        }
        return project;
    },
    async [PROJECT_TYPES.DELETE_PROJECT_NOTE]({ dispatch }, { inputNote }) {
        const response = await ApiService.delete("projects/" + inputNote.project_id + "/notes/" + inputNote.id, inputNote);
        if (response) {
            dispatch(PROJECT_TYPES.FETCH_PROJECTS_NOTES, {
                inputProject: { id: inputNote.project_id },
            })
            return response
        }
    },
    async [PROJECT_TYPES.CREATE_PROJECT_TIMELOG](_1, { inputTimeLog }) {
        const response = await ApiService.post("projects/" + inputTimeLog.id + "/timeLogs", inputTimeLog);
        const project = getData(response, 'data')
        return project;
    },

    async [PROJECT_TYPES.FETCH_PROJECT_TIMELOG]({ commit }, { inputTimeLog }) {
        const response = await ApiService.query("projects/" + inputTimeLog.id + "/timeLogs");
        const logs = getData(response, 'data')
        if (logs) {
            commit(PROJECT_TYPES.SET_PROJECT_TIMELOG, logs)
        }
    },

    async [PROJECT_TYPES.DELETE_PROJECT_TIMELOG]({ dispatch }, { inputTimeLog }) {
        const response = await ApiService.delete("projects/" + inputTimeLog.project.id + "/timeLogs/" + inputTimeLog.id, inputTimeLog);
        if (response) {
            dispatch(PROJECT_TYPES.FETCH_PROJECT_TIMELOG, {
                inputTimeLog: inputTimeLog.project,
            })
            // dispatch(PROJECT_TYPES.FETCH_PROJECTS)
            return response
        }
    },
    async [PROJECT_TYPES.FETCH_WORKLOAD_OPTIONS]({commit}) {
        const response = await ApiService.query("options");
        const data = getData(response, 'data')
        if (data) {
            commit(PROJECT_TYPES.SET_WORKLOAD_OPTIONS, data.projects)
        }
    },


};
const mutations = {
    [PROJECT_TYPES.SET_PROJECTS]: (state, array) =>
        (state.projects = array),
        [PROJECT_TYPES.SET_WORKLOAD_OPTIONS]: (state, array) =>
        (state.workload_options = array),
        [PROJECT_TYPES.SET_PROJECT]: (state, array) =>
            (state.project = array),
    [PROJECT_TYPES.SET_DASHBOARD_DATA]: (state, array) =>
        (state.dashboard = array),
    [PROJECT_TYPES.SET_PROJECT_TIMELOG]: (state, array) =>
        (state.project_timelog = array),
    [PROJECT_TYPES.SET_ARCHIV_PROJECTS]: (state, array) =>
        (state.archiv_projects = array),
    [PROJECT_TYPES.SET_PROJECTS_NOTES]: (state, array) =>
        (state.projects_notes = array),
    [PROJECT_TYPES.SET_PROJECT_DESIGNATIONS]: (state, array) =>
        (state.projects_designations = array),
    [PROJECT_TYPES.SET_PROJECT_CUSTOMERS]: (state, array) =>
        (state.projects_customers = array),
    [PROJECT_TYPES.SET_PROJECT_MILESTONE_LOGS]: (state, array) =>
        (state.project_milestone_logs = array),
    [PROJECT_TYPES.INCREMENT_TIMER]: (state, input) => {

        if (state.timer[input] == null) {
            state.timer[input] = 0;
        }
        state.timer[input]++;
    },
    [PROJECT_TYPES.SET_INPUT]: (state, input) => {
        state.input = input;
    },
    [PROJECT_TYPES.CLEAR_INPUT]: (state) => {
        state.input = {
            projectnumber: "",
            contractnumber: "",
            customer: "",
            partnumber: "",
            designation: "",
            template: "",
            clerk_name: "",
            responsible_name: "",
            constructor_name: "",
            workload_construction: "",
            workload_management: "",
            begin_date: "",
            customer_drawing_date: "",
            deliver_date: "",
            force: false,
        }
    },
    [PROJECT_TYPES.SET_PROJECT_STATUS]: (state,payload) => {
        state.projects.forEach(project => {
            if(project.id == payload.id)
            {
                console.log("FOUND");
                project = payload
            }
        });
    }

};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
