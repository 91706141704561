<template>

  <svg
    id="Folder_closed"
    xmlns="http://www.w3.org/2000/svg"
    width="21.383"
    height="15.998"
    viewBox="0 0 21.383 15.998"
  >
    <path
      id="Pfad_417"
      data-name="Pfad 417"
      d="M364.829,325.338H347.435a1.086,1.086,0,0,1-1.069-1.1l-.923-7.821a1.1,1.1,0,0,1,1.065-1.169h19.249a1.085,1.085,0,0,1,1.069,1.1l-.931,7.962A1.066,1.066,0,0,1,364.829,325.338Zm-.139-1.118a.08.08,0,0,0,0,.019Zm-17.123-.093H364.7l.9-7.667H346.662Z"
      transform="translate(-345.442 -309.341)"
      
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_418"
      data-name="Pfad 418"
      d="M365.22,315.048a.606.606,0,0,1-.605-.605v-3.682a59.042,59.042,0,0,1-9.648-.548,9.376,9.376,0,0,1-1.878-.539,6.175,6.175,0,0,0-2.619-.528c-.45.024-1.809.24-2.117.889a1.261,1.261,0,0,0-.08.2v4.208a.605.605,0,1,1-1.211,0l.02-4.448a2.527,2.527,0,0,1,.186-.5c.663-1.394,2.888-1.547,3.14-1.561a7.266,7.266,0,0,1,3.1.6,8.306,8.306,0,0,0,1.642.477,58.284,58.284,0,0,0,9.578.53,1.024,1.024,0,0,1,.748.289,1.166,1.166,0,0,1,.35.842v3.767A.606.606,0,0,1,365.22,315.048Zm-.446-4.293h0Z"
      transform="translate(-345.755 -307.928)"
      
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArchivIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>