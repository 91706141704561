import { createI18n } from "vue-i18n";
const language = {
    de: {
        checked: "Geprüft",
        begin_date:'Startdatum',
        customer_drawing_date: 'Lieferdatum Kundenzeichnung',
        cancel: "Abbrechen",
        continue: "Weiter",
        calender: "Kalender",
        customer: 'Kunde',
        comment: 'Bemerkung',
        constructor: 'Konstruktion',
        contractnumber: 'Auftragsnummer',
        clerk_id: 'Vertrieb/Service Mitarbeiter',
        constructor_id: 'Konstrukteur',
        stand_in: 'Vertretung Vertrieb/Service',
        workload_manufacturing_drawing:"AW Fertigungszeichnung (Std.)",
        workload_detail_construction:"AW Detailkonstruktion (Std.)",
        workload_customer_drawing:"AW Kundenzeichnung (Std.)",
        lt_manufacturing_drawing: "LT Fertigungszeichnung",
        lt_detail_construction: "LT Detailkonstruktion",
        lt_customer_drawing: "LT Kundenzeichnung",
        lt_check_date: "LT Prüfung/drucken",
        lt_documentation_date: "LT Dokumentation",
        calendar_management: "Kalenderverwaltung",
        pruefung_drucken: "Prüfung/drucken",
        Fertigungszeichnung: "Fertigungszeichnung",
        Detailkonstruktion: "Detailkonstruktion",
        Kundenzeichnung: "Kundenzeichnung",
        Dokumentation: "Dokumentation",

        ready:'Zur Prüfung',
        send:'Versendet',
        redo: 'Überarbeiten',

        global_read: 'Komplette Projektansicht',

        dashboard: "Dashboard",
        description: 'Bezeichnung',
        designation: 'Bezeichnung',
        distribution_service: 'Vertrieb/Service',
        deliver_date: 'Lieferdatum',

        email: "E-Mail",
        email_repeat: "E-Mail wiederholen",
        expenditure_management: 'AW Leitung (Std.)',
        expenditure_constructor: 'AW Konstrukteur (Std.)',
        editProfile: "Profil bearbeiten",
        searchUser: "Benutzer suchen",

        fullscreen_toggle: "Vollbildmodus aktivieren/deaktivieren",
        filter: "Filtern",
        filter_toggle: "Filter ein/ausblenden",
        first_name: "Vorname",
        firstname: "Vorname",
        calendar: "Kalender",
        calendar_type: "Kalendarart",
        month_view: "Monatsansicht",
        week_view: "Wochenansicht",

        login: "Anmeldung",
        logout: "Abmelden",
        login_return: "Zurück zum Login",
        last_name: "Nachname",
        lastname: "Nachname",
        order_number: 'Auftragsnummer',
        partnumber: "Teilenummer",
        password: "Passwort",
        password_forgot: "Passwort vergessen?",
        password_new: "Neues Passwort",
        password_repeat: "Passwort wiederholen",
        password_reset: "Passwort zurücksetzen",
        part_number: 'Teilnummer',
        personal: "Personal",
        projects: "Projekte",
        project_number: 'Projektnummer',
        projectnumber: 'Projektnummer',
        project_create: 'Projektverwaltung',
        personnel_management: 'Personalverwaltung',
        responsible_id: 'Verantwortlicher',
        sort: 'Sortierung',
        customer_approval: "Kundenfreigabe",


        lt_customer: 'LT Kundenzeichnung',
        lt_mashine: 'LT Maschine',
        individualise: 'Individualisierung',

        stopped: 'Pausiert',
        started: 'In Arbeit',
        finished: 'Fertig',

        startdate:'Startdatum',
        enddate:'Enddatum',
        time:'Uhrzeit',
        type:'Typ',
        personel: 'Personal',

        resend_code: "Code erneut senden",
        responsible: 'Verantwortlich',
        planning: "Planung",

        settings: "Einstellungen",
        shortcuts: "Tastenkürzel",
        sign_in: "Anmelden",
        stay_logged_in: "Angemeldet bleiben",
        service: "Vertrieb/Service",
        start_date: "Startdatum",

        workload_management: 'Aufwand Leitung',
        workload_construction: 'Aufwand Konstrukteur',

        menu_toggle: "Menüleiste ein/ausblenden",
        template: "Vorlage",
        trust_browser: "Browser vertrauen?",
        two_fa: "2 Faktor Authentifizierung",
        two_fa_text:
            "Bitte geben Sie den fünfstelligen Code aus der E-Mail ein. Keinen Code erhalten?",
        task: "Aufgabe",
        hours_per_week: "Stunden pro Woche",

        toast: {
            login_failed: "Anmeldung ist fehlgeschlagen.",
        },
        /***
         * Settings
         ***/ speedUpWork: "Beschleunigen Sie Ihre Arbeitsweise",
        useShortcutsText:
            "Nutzen Sie Tastenkürzel, um bspw. Inhalte schneller zu durchsuchen.",
        selectionFields: "Auswahlfelder",
        changeSelectionText:
            "Ändern Sie diverse Auswahlfelder wie die Meilensteine oder Status",
    },
    en: {
        dashboard: "Dashboard",
        login: "Login",
        logout: "Logout",
        toast: {
            login_failed: "Login failed.",
        },
    },
};

const i18n = createI18n({
    messages: language,
    locale: "de",
    fallbackLocale: "de",
});

export default i18n;
