<template>
  <div class="div h-16 w-full" :class="$route.meta.isLoggedIn ? '' : 'd-none'">
    <nav
      class="flex w-full items-center fixed left-0 top-0 h-16 bg-white lg:pl-20"
    >
      <icon-container
        class="ml-2 hidden hover:bg-white-off transition-colors"
        icon="SearchIcon"
        iconFill="fill-grey"
        @click="toggleSearchbar"
      ></icon-container>
      <router-link :to="{ name: 'Dashboard' }" class="w-24">
        <logo
          class="transform -ml-36"
          style="--tw-scale-x: 0.3; --tw-scale-y: 0.3"
        />
      </router-link>
      <div v-if="GET_USER.task == 'Konstruktion'" class="absolute top-5 right-64 flex flex-row">
        <div
          class="mr-8 flex flex-row cursor-pointer"
          id="hours"
          @click="FETCH_PROJECTS(), FETCH_OPTIONS(),state.showModal = true"
        >
          <clock-icon class="mr-2 mt-1" />
          <p>Aufwand verbuchen</p>
        </div>
        <cross-icon
          class="mr-3 mt-2 transform scale-125 cursor-pointer"
          :fillClass="'fill-red'"
          @click="state.openConfirmDelete = true"
        ></cross-icon>
        <p>{{ CONVERT_TIME(state.timer) }}</p>
        <div
          class="h-6 w-6 rounded-md mt-px ml-3 cursor-pointer"
          :class="`${state.timer_start ? 'bg-red' : 'bg-green'} transition`"
          @click="startStopTimer(!state.timer_start)"
        >
          <arrow-icon
            v-if="!state.timer_start"
            :fillClass="'fill-white'"
            class="transform -rotate-90 mt-2 ml-1.5"
          />
          <div v-else class="flex mt-1.5 justify-center">
            <div class="bg-white h-3 w-1 rounded-xl" />
            <div class="bg-white h-3 w-1 rounded-xl ml-0.5" />
          </div>
        </div>
      </div>
      <div class="flex ml-auto mr-5">
        <div class="flex" id="navbar-actions"></div>
        <dropdown class="ml-6">
          <avatar-bubble
            :name="GET_USER.fullname"
            :image="GET_USER.picture"
          ></avatar-bubble>
          <template #items>
            <div
              class="flex items-center w-full cursor-pointer px-2 text-black hover:bg-white-off"
              style="z-index:1000;"
              @click="LOGOUT()"
            >
              <logout-icon
                class="mr-2 w-4"
                fillClass="fill-black"
              ></logout-icon>
              {{ $t("logout") }}
            </div>
          </template>
        </dropdown>
      </div>
    </nav>
    <div>
      <add-workhours
        v-show="state.showModal"
        :time="state.timer"
        :projects="state.projects"
        class="absolute z-50"
        @agree="clearAll()"
        @disagree="state.showModal = false"
      ></add-workhours>
    </div>
    <confirm-delete v-if="state.openConfirmDelete" :project="project" @agree="clearTimer()" @disagree="state.openConfirmDelete=false"></confirm-delete>
  </div>
</template>

<script>
import { defineComponent, computed, reactive } from "vue";
import { useStore } from "vuex";
import {
  STORE_TYPES,
  HELPERS_TYPES,
  SEARCH_TYPES,
  AUTH_TYPES,
  PROJECT_TYPES,
} from "../../store/types";
import AvatarBubble from "../global/AvatarBubble.vue";
import ClockIcon from "../icons/ClockIcon";
import ArrowIcon from "../icons/ArrowIcon";
import CrossIcon from "../icons/CrossIcon";
import Dropdown from "../global/Dropdown.vue";
import IconContainer from "../global/IconContainer.vue";
import AddWorkhours from "../../views/projects/modals/AddWorkhours.vue";
import Logo from "../logo/logo.vue";
import ConfirmDelete from "../../views/projects/modals/AddWorkhours/RemoveWorkhours.vue"

export default defineComponent({
  components: {
    AvatarBubble,
    IconContainer,
    Dropdown,
    Logo,
    ArrowIcon,
    ClockIcon,
    AddWorkhours,
    CrossIcon,
    ConfirmDelete,
  },
  name: "Navbar",
  setup() {
    const store = useStore();

    const state = reactive({
      showSearch: false,
      timer_start: false,
      timer: 0,
      showModal: false,
      openConfirmDelete: false,
      projects: [],
    });
    /**
     *  Functions
     */
     async function FETCH_PROJECTS() {
      await store.dispatch(
        `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.FETCH_PROJECTS}`
      );
     }
    function SET_IS_SIDEBAR_ACTIVE(value) {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE}`,
        value
      );
    }

    function toggleSearchbar() {
      console.log("yeehaw");
      state.showSearch = !state.showSearch;
    }
    function LOGOUT() {
      return store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.LOGOUT}`);
    }

    function clearTimer() {
      localStorage.removeItem("timer");
      state.timer = 0;
    }

    function startStopTimer(value){
      state.timer_start = value;
      localStorage.setItem("timerRunning",value)
    }

    function CONVERT_TIME(time) {
      var minutes = Math.floor(time / 60);
      var seconds = Math.floor(time % 60);
      var hours = Math.floor(minutes / 60);
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      if (hours != 0) {
        console.log(minutes);
        if (minutes == 0) {
          minutes = "0" + minutes;
        }
        return hours + ":" + minutes + ":" + seconds;
      } else {
        if (minutes < 10) {
          return "0" + minutes + ":" + seconds;
        }
        return minutes + ":" + seconds;
      }
    }

    function clearAll() {
      state.showModal = false;
      clearTimer();
    }

    async function FETCH_OPTIONS(){
      store.dispatch(
        `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.FETCH_WORKLOAD_OPTIONS}`
      )
      state.projects =
        store.getters[`${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.GET_WORKLOAD_OPTIONS}`];
    }

    /**
     *  Computed
     */
    const GET_IS_SIDEBAR_ACTIVE = computed(
      () =>
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
        ]
    );
    const GET_SEARCH_ITEMS = computed(
      () =>
        store.getters[`${STORE_TYPES.SEARCH}/${SEARCH_TYPES.GET_SEARCH_ITEMS}`]
    );
    const search = computed({
      set(value) {
        console.log("SET ", value);
        store.commit(`${STORE_TYPES.SEARCH}/${SEARCH_TYPES.SET_SEARCH}`, value);
      },
      get() {
        return store.getters[
          `${STORE_TYPES.SEARCH}/${SEARCH_TYPES.GET_SEARCH}`
        ];
      },
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    /**
     * created
     */
    if (localStorage.getItem("timer") != null) {
      state.timer = JSON.parse(localStorage.getItem("timer"));
    }
    setTimeout(() => {
       let started = JSON.parse(localStorage.getItem("timerRunning"))
      state.timer_start = started
    }, 100);
    setInterval(() => {
      let started = JSON.parse(localStorage.getItem("timerRunning"))
      state.timer_start = started
      if (started == true) {
        state.timer++;
        localStorage.setItem("timer", state.timer);
      }
    }, 60000);

    return {
      //State
      state,
      // Computed
      search,

      GET_IS_SIDEBAR_ACTIVE,
      GET_SEARCH_ITEMS,
      GET_USER,
      // Functions
      SET_IS_SIDEBAR_ACTIVE,
      toggleSearchbar,
      LOGOUT,
      CONVERT_TIME,
      clearTimer,
      clearAll,
      FETCH_PROJECTS,
      FETCH_OPTIONS,
      startStopTimer,
    };
  },
});
</script>

<style lang="scss">
.d-none {
  display: none;
  &:only-child {
    display: none;
  }
}
.searchbar {
  @screen lg {
    width: 500px;
  }
  @screen xl {
    width: 545px;
  }
}
</style>

<style scoped>
#hours:hover p {
  transition: 0.1s;
  color: #00a0d0;
}
</style>
