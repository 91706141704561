<template>
    <div class="max-w-full flex flex-row items-center justify-center">
        <button
            :class="IS_LOADING ? bgColor + ' isLoading' : bgColor"
            class="custom-button  rounded-xl flex flex-row items-center justify-center cursor-pointer hover:bg-opacity-80 transition-all w-full h-full"
        >
            <div
                class="flex flex-row items-center justify-center"
                v-if="!IS_LOADING"
            >
                <slot name="icon"></slot>
                <h3 class="font-semibold transition-all" :class="textColor">
                    {{ text }}
                </h3>
            </div>
            <div v-else class="w-10 h-10 flex items-center justify-center">
                <div
                    class="three-quarter-spinner  border-4"
                    :class="'border-' + textColor.substring(5)"
                ></div>
            </div>
        </button>
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
export default defineComponent({
    name: "CustomButton",
    props: {
        text: {
            type: String,
            required: true,
        },
        bgColor: {
            type: String,
            required: true,
        },
        textColor: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const store = useStore();

        /**
         *  Computed
         */
        const IS_LOADING = computed(() => {
            const loadingIds =
                store.getters[
                    `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_LOADING_IDS}`
                ];
            let isLoading = loadingIds.find((loadingId) => {
                return loadingId == props.id;
            });
            if (isLoading) {
                return true;
            }
            return false;
        });
        return {
            // Computed
            IS_LOADING,
        };
    },
});
</script>

<style lang="scss">
.custom-button {
    &:active {
        margin: 1px 2px;
        width: calc(100% - 4px);
        height: calc(100% - 2px);
        h3 {
            font-size: 15px;
        }
    }
    &.isLoading {
        width: 40px !important;
        height: 40px !important;
        border-radius: 40px;
    }
}
</style>
<style scoped>

.three-quarter-spinner {
    width: 30px;
    height: 30px;

    border-top: 3px solid transparent;
    border-radius: 50%;
    animation: spin 0.7s linear 0s infinite;
}
</style>
