import { HELPERS_TYPES } from "../types";
import router from "../../router";

const state = {
    isSidebarActive: true,
    getFilterRouteName: null,
    loadingIds: [],
    errorIds: {},
    projectCount: [],
    emptyFields: [],
};
const getters = {
    [HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE]: (state) => state.isSidebarActive,
    [HELPERS_TYPES.GET_FILTER_ACTIVE_ROUTE]: (state) =>
        state.getFilterRouteName,
    [HELPERS_TYPES.GET_LOADING_IDS]: (state) => state.loadingIds,
    [HELPERS_TYPES.GET_ERROR_IDS]: (state) => state.errorIds,
    [HELPERS_TYPES.GET_PROJECT_COUNT]: (state, user) => state.projectCount[user],
    [HELPERS_TYPES.GET_COUNT]: (state) => state.projectCount,
    [HELPERS_TYPES.GET_EMPTYFIELDS]: (state) => state.emptyFields,
};
const actions = {};
const mutations = {
    [HELPERS_TYPES.SET_IS_SIDEBAR_ACTIVE]: (state, value) =>
        (state.isSidebarActive = value),
    [HELPERS_TYPES.SET_EMPTYFIELDS]: (state, value) =>
        (state.emptyFields = value),
    [HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE]: (state) => {
        if (state.getFilterRouteName) {
            state.getFilterRouteName = null;
        } else {
            state.getFilterRouteName = router.currentRoute.value.name;
        }
    },
    [HELPERS_TYPES.SET_LOADING_IDS]: (state, array) =>
        (state.loadingIds = array),
    [HELPERS_TYPES.SET_LOADING_ID]: (state, string) =>
        state.loadingIds.push(string),
    [HELPERS_TYPES.REMOVE_LOADING_ID]: (state, string) =>
    (state.loadingIds = state.loadingIds.filter(
        (loadingId) => loadingId != string
    )),
    [HELPERS_TYPES.SET_ERROR_IDS]: (state, object) => (state.errorIds = object),
    [HELPERS_TYPES.SET_PROJECT_COUNT]: (state, object) => (state.projectCount[object.user] = object.count),
    [HELPERS_TYPES.SET_ERROR_ID_OBJECT]: (state, object) =>
        (state.errorIds = Object.assign(state.errorIds, object)),
    [HELPERS_TYPES.REMOVE_ERROR_ID]: (state, string) =>
        (state.errorIds[string] = null),
    [HELPERS_TYPES.CLEAR_ERROR_IDS]: (state) => (state.errorIds = {}),
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
