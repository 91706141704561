<template>
    <navbar></navbar>
    <div v-if="$route.meta.isLoggedIn" class="h-full">
        <sidebar></sidebar>
        <div class="h-full">
            <router-view
                class="routerview pt-4 pl-4 pb-4 pr-2 mr-2 transition-all scrollbar overflow-y-auto"
                :class="GET_IS_SIDEBAR_ACTIVE ? 'lg:ml-70px' : ''"
            />
        </div >
    </div>
    <div v-else>
        <router-view />
    </div>
    <loading-animation />
</template>
<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { STORE_TYPES, HELPERS_TYPES } from "./store/types";
import Navbar from "./components/layout/Navbar.vue";
import Sidebar from "./components/layout/Sidebar.vue";
import LoadingAnimation from "./components/global/LoadingAnimation.vue";
import ShortcutHelper from "./common/shortcutHelper";
export default defineComponent({
    components: { Sidebar, Navbar, LoadingAnimation },
    setup() {
        const store = useStore();

        const state = reactive({
          shortcutHelper: new ShortcutHelper()
        })

        /**
         *  Computed
         */
        const GET_IS_SIDEBAR_ACTIVE = computed(
            () =>
                store.getters[
                    `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_IS_SIDEBAR_ACTIVE}`
                ]
        );

        /**
         * Function
         */
        // async function FETCH_USER(){
        //   await store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_USER}`)
        // }
        // async function FETCH_DASHBOARD_DATA() {
        //     await store.dispatch(
        //         `${STORE_TYPES.PROJECTS}/${PROJECT_TYPES.FETCH_DASHBOARD_DATA}`
        //     );
        // }

    //     Promise.all([FETCH_USER()]).then(() => {
    //       FETCH_DASHBOARD_DATA
    // });


    /**
     * created
     */

    state.shortcutHelper.setGlobalShortcuts()

        return {
            GET_IS_SIDEBAR_ACTIVE,
        };
    },
});
</script>

<style>
.short__display {
 text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  display: hidden;
  
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #faf8f8;
  opacity: 0.2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e4e4e7;
  border-radius: 5px;
  display: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c0c0c5;
  cursor: pointer;
}


.fade-enter-from {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: 0.3s;
}

.slide-enter-from {
  opacity: 0;
  transform: translateX(100%);
}
.slide-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.slide-enter-active,
.slide-leave-active {
  transition: 0.3s;
}

.drop-enter-from{
  opacity: 0;
  max-height: 0;
  transform: scaleY(0);
}
.drop-enter-to{
  opacity: 100%;
  max-height: 200vh;
}
.drop-enter-active{
  transition: max-height 0.5s ease-out, transform 0.2s;
   transform-origin: top;
}
</style>

<style lang="scss" scoped>
.routerview {
    height: calc(100vh - 125px);
    @screen lg {
        height: calc(100vh - 75px);
    }
}
</style>
