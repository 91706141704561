<template>
    <router-link
        :to="link.link"
        active-class="active"
        class="h-14 w-70px  flex flex-col items-center justify-center  transition-colors"
        :class="
            $route.name == link.link.name ||
            $route.path.match(link.link.name.toLowerCase())
                ? 'bg-white'
                : 'hover:bg-white hover:bg-opacity-20'
        "
    >
        <component
            v-bind:is="link.icon"
            :fillClass="
                $route.name == link.link.name ||
                $route.path.match(link.link.name.toLowerCase())
                    ? 'fill-primary'
                    : 'fill-white'
            "
            :strokeClass="
                $route.name == link.link.name ||
                $route.path.match(link.link.name.toLowerCase())
                    ? 'stroke-primary'
                    : 'stroke-white'
            "
        ></component>
        <p class=" text-xs pt-1 transition-color" :class="$route.name == link.link.name || $route.path.match(link.link.name.toLowerCase()) ? 'text-primary' : 'text-white'">{{link.name}}</p>
    </router-link>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        link: {
            type: Object,
            required: true,
        },
    },
});
</script>

<style></style>
