<template>
    <div
        class="relative flex justify-center items-center"
        style="z-index:10000;"
        ref="dropdowncomponent"
    >
        <div
            class="overflow-hidden cursor-pointer focus:outline-none"
            :class="state.isDropdownActive ? 'active' : ''"
            @click="openDropdown"
            ref="trigger"
        >
            <slot></slot>
        </div>
        <div
            class="z-20 bg-white rounded-lg fixed flex flex-col opacity-0 h-auto max-h-0 right-0 overflow-hidden w-56 transition-all"
            ref="dropdown"
            :class="
                state.isDropdownActive
                    ? 'border border-grey-light py-2 overflow-auto opacity-100 max-h-80 scrollbar'
                    : ''
            "
        >
            <slot name="items"> </slot>
        </div>
    </div>
</template>

<script>
import {
    defineComponent,
    onBeforeUnmount,
    onMounted,
    reactive,
    ref,
} from "vue";
import { clickOutside } from "../../common/customDirectives";
export default defineComponent({
    setup() {
        const dropdowncomponent = ref();
        const dropdown = ref();
        const trigger = ref();

        /**
         * State
         */
        const state = reactive({
            isDropdownActive: false,
        });

        /**
         * Functions
         */
        const moveDropdownToTrigger = function() {
            let offsetLeft =
                trigger.value.getBoundingClientRect().left +
                trigger.value.clientWidth / 1.4;
            let offsetTop =
                trigger.value.getBoundingClientRect().top +
                trigger.value.clientHeight / 2;

            if (window.innerWidth - offsetLeft > 220) {
                dropdown.value.style.left = offsetLeft + "px";
            } else {
                dropdown.value.style.left = offsetLeft - 220 + "px";
            }

            dropdown.value.style.top = offsetTop + 25 + "px";
        };

        function openDropdown() {
            state.isDropdownActive = !state.isDropdownActive;
            moveDropdownToTrigger();
            window.addEventListener("resize", moveDropdownToTrigger);
        }

        function closeDropdown() {
            state.isDropdownActive = false;
            window.removeEventListener("resize", moveDropdownToTrigger);
        }

        /**
         * Mounted
         */
        onMounted(() => {
            clickOutside(dropdowncomponent.value, closeDropdown);
        });
        /**
         * Before Unmounted
         */
        onBeforeUnmount(() => {
            window.removeEventListener("resize", moveDropdownToTrigger);
        });
        return {
            // Refs
            dropdowncomponent,
            dropdown,
            trigger,

            // State
            state,

            // Functions
            openDropdown,
            closeDropdown,
            moveDropdownToTrigger,
        };
    },
});
</script>

<style></style>
