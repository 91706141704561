<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g id="Warning" transform="translate(18 18) rotate(180)">
      <circle
        id="Ellipse_90"
        data-name="Ellipse 90"
        cx="9"
        cy="9"
        r="9"
        :class="fillClass + ' transition-fill-opacity'"
      />
      <rect
        id="Rechteck_914"
        data-name="Rechteck 914"
        width="2"
        height="6"
        rx="0.5"
        transform="translate(8 4)"
        fill="#fff"
      />
      <rect
        id="Rechteck_915"
        data-name="Rechteck 915"
        width="2"
        height="2"
        rx="0.5"
        transform="translate(8 12)"
        fill="#fff"
      />
    </g>
  </svg>
</template>


<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "InfoIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>