<template>
  <svg
    id="MultiAction"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <rect
      id="Rechteck_923"
      data-name="Rechteck 923"
      width="4"
      height="4"
      rx="0.5"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_924"
      data-name="Rechteck 924"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(6)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_925"
      data-name="Rechteck 925"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(12)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_926"
      data-name="Rechteck 926"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(0 6)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_927"
      data-name="Rechteck 927"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(6 6)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_928"
      data-name="Rechteck 928"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(12 6)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_929"
      data-name="Rechteck 929"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(0 12)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_930"
      data-name="Rechteck 930"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(6 12)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_931"
      data-name="Rechteck 931"
      width="4"
      height="4"
      rx="0.5"
      transform="translate(12 12)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "KachelIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>