<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.744"
    height="20.065"
    viewBox="0 0 17.744 20.065"
  >
    <g id="Human" transform="translate(0)">
      <path
        id="Pfad_263"
        data-name="Pfad 263"
        d="M120.386,82.75c-4.975,0-8.872-2.368-8.872-5.391s3.9-5.392,8.872-5.392,8.872,2.368,8.872,5.392S125.361,82.75,120.386,82.75Zm0-9.283c-4,0-7.372,1.783-7.372,3.892s3.376,3.891,7.372,3.891,7.372-1.782,7.372-3.891S124.382,73.467,120.386,73.467Z"
        transform="translate(-111.514 -62.685)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_264"
        data-name="Pfad 264"
        d="M120.386,73.455A5.419,5.419,0,1,1,125.2,68.07,5.128,5.128,0,0,1,120.386,73.455Zm0-9.27A3.935,3.935,0,1,0,123.7,68.07,3.636,3.636,0,0,0,120.386,64.185Z"
        transform="translate(-111.514 -62.685)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PersonalIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>