<template>
  <div
    class="
      input-text
      flex flex-row
      items-center
      border
      relative
      transition-all
      max-w-full
      cursor-pointer
    "
    :class="
      heightClass + ' ' + borderClass + ' ' + focusWithinClass + ' ' + isLoading
    "
    @click="focusInput"
  >
    <div class="absolute top-3 left-3 flex-shrink-0">
      <slot name="icon" v-if="$slots.icon != null"> </slot>
    </div>

    <input
      ref="input"
      class="pl-3 border-none w-full h-11 rounded-xl"
      :class="`${(inputClass + ' ', $slots.icon != null ? 'pl-9' : '')} ${
        inputDisabled ? '' : 'bg-transparent'
      }`"
      :id="id"
      :name="name"
      :type="type"
      :value="modelValue"
      :autocomplete="'off'"
      :disabled="inputDisabled"
      :title="
        inputDisabled
          ? 'Wochenstunden werden nur bei Konstrukteuren benötigt.'
          : ''
      "
      @input="updateInput"
      @change="changeInput"
      @focus="inputDisabled ? '' : focus, $emit('focus')"
      @blur="$emit('blur')"
      @keyup.enter="$emit('enter')"
    />
    <p v-if="type == 'date'" class="absolute" :class="`${errorMessage ? 'right-16' : 'right-10'}`">
      {{ getCalendarWeek(modelValue) }}
    </p>
    <label
      @click="$emit('click')"
      :for="id"
      class="
        text-grey
        absolute
        transition-all
        px-1
        left-2
        z-10
        cursor-text
        rounded-md
      "
      :class="[
        type == 'date' || type == 'time' || lifted
          ? labelClass + ' -top-3 text-sm'
          : (modelValue && modelValue != '')
          ? labelClass + ' -top-3 text-sm'
          : 'top-2 text-md',
        $slots.icon != null && (!modelValue || modelValue == '')
          ? 'left-8'
          : '',
      ]"
      >{{ label }}</label
    >
    <error-message-button
      v-if="errorMessage"
      :message="errorMessage[0]"
      :class="`${type == 'date' ? 'mr-7' : ''}`"
    />
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
// import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import ErrorMessageButton from "../global/ErrorMessageButton.vue";
export default defineComponent({
  components: { ErrorMessageButton },
  name: "InputText",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    heightClass: {
      type: String,
      default: "h-11",
    },
    borderClass: {
      type: String,
      default: "border-grey-light hover:border-grey rounded-xl",
    },
    focusWithinClass: {
      type: String,
      default: "focus-within:border-grey",
    },
    inputClass: {
      type: String,
      default: "pl-3",
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
    lifted: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "update:modelValue",
    "update:value",
    "change",
    "focus",
    "click",
    "blur",
    "error",
    "enter"
  ],
  setup(props, ctx) {
    const store = useStore();
    var moment = require("moment");
    /**
     * refs
     */
    const input = ref();
    // const i18n = useI18n();
    /**
     * functions
     */
    function focusInput() {
      if (input.value) {
        input.value.focus();
      }
      ctx.emit("click");
    }
    /**
     * emits
     */
    function updateInput(event) {
      REMOVE_ERROR_ID();
      const newValue = event.target.value;
      ctx.emit("update:modelValue", newValue);
    }
    function changeInput(event) {
      const newValue = event.target.value;
      ctx.emit("change", newValue);
    }
    function focus(event) {
      ctx.emit("focus", event);
    }
    function REMOVE_ERROR_ID() {
      if (errorMessage.value) {
        store.commit(
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_ERROR_ID}`,
          props.name
        );
      }
    }
    function getCalendarWeek(date) {
      if (date != "") {
        if (!isNaN(moment(date).week())) {
          return "KW: " + moment(date).isoWeek();
        }
      }
      return "";
    }
    /**
     *  Computed
     */
    const errorMessage = computed(() => {
      const errorIds =
        store.getters[`${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_ERROR_IDS}`];
      let error = errorIds[props.name];
      if (error) {
        // return (i18n.t(props.name)+" wird benötigt.");
        ctx.emit("error", error);
        return error;
      }
      return error;
    });
    const isLoading = computed(() => {
      const loadingIds =
        store.getters[
          `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_LOADING_IDS}`
        ];
      let loading = loadingIds.find((loadingId) => loadingId == props.id);

      return loading ? "border-loading" : "";
    });
    return {
      //refs
      input,
      //functions
      focusInput,
      REMOVE_ERROR_ID,
      //emits
      updateInput,
      changeInput,
      focus,
      //computed
      errorMessage,
      isLoading,
      getCalendarWeek,
    };
  },
});
</script>
<style lang="scss">
.border-loading {
  border-color: #0c7c59 !important;
  label {
    color: #0c7c59 !important;
  }
}
</style>

<style>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  padding-right: 10px;
}
</style>
