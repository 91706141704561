<template>
  <svg
    id="Exit"
    xmlns="http://www.w3.org/2000/svg"
    width="9.018"
    height="9.018"
    viewBox="0 0 9.018 9.018"
  >
    <rect
      id="Rechteck_973"
      data-name="Rechteck 973"
      width="1.093"
      height="11.66"
      rx="0.5"
      transform="translate(0 0.773) rotate(-45)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_974"
      data-name="Rechteck 974"
      width="1.093"
      height="11.66"
      rx="0.5"
      transform="translate(8.245 0) rotate(45)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "CommunicationIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>