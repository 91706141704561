<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.065"
    height="21.065"
    viewBox="0 0 21.065 21.065"
  >
    <g id="Dashboard" transform="translate(0)">
      <path
        id="Pfad_254"
        data-name="Pfad 254"
        d="M84.481,45.967H78.329a1.752,1.752,0,0,1-1.75-1.75V36.435a1.752,1.752,0,0,1,1.75-1.75h6.152a1.752,1.752,0,0,1,1.75,1.75v7.782A1.752,1.752,0,0,1,84.481,45.967Zm-6.152-9.782a.25.25,0,0,0-.25.25v7.782a.25.25,0,0,0,.25.25h6.152a.25.25,0,0,0,.25-.25V36.435a.25.25,0,0,0-.25-.25Z"
        transform="translate(-76.579 -34.685)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_255"
        data-name="Pfad 255"
        d="M95.894,55.75H89.742A1.752,1.752,0,0,1,87.992,54V46.217a1.752,1.752,0,0,1,1.75-1.75h6.152a1.752,1.752,0,0,1,1.75,1.75V54A1.752,1.752,0,0,1,95.894,55.75Zm-6.152-9.783a.251.251,0,0,0-.25.25V54a.25.25,0,0,0,.25.25h6.152a.25.25,0,0,0,.25-.25V46.217a.251.251,0,0,0-.25-.25Z"
        transform="translate(-76.579 -34.685)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_256"
        data-name="Pfad 256"
        d="M84.481,55.75H78.329A1.752,1.752,0,0,1,76.579,54V49.478a1.752,1.752,0,0,1,1.75-1.75h6.152a1.752,1.752,0,0,1,1.75,1.75V54A1.752,1.752,0,0,1,84.481,55.75Zm-6.152-6.522a.25.25,0,0,0-.25.25V54a.25.25,0,0,0,.25.25h6.152a.25.25,0,0,0,.25-.25V49.478a.25.25,0,0,0-.25-.25Z"
        transform="translate(-76.579 -34.685)"
        :class="fillClass + ' transition-fill'"
      />
      <path
        id="Pfad_257"
        data-name="Pfad 257"
        d="M95.894,42.707H89.742a1.753,1.753,0,0,1-1.75-1.75V36.435a1.752,1.752,0,0,1,1.75-1.75h6.152a1.752,1.752,0,0,1,1.75,1.75v4.522A1.753,1.753,0,0,1,95.894,42.707Zm-6.152-6.522a.25.25,0,0,0-.25.25v4.522a.251.251,0,0,0,.25.25h6.152a.251.251,0,0,0,.25-.25V36.435a.25.25,0,0,0-.25-.25Z"
        transform="translate(-76.579 -34.685)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>
