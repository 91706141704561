<template>
  <svg
    id="Project"
    xmlns="http://www.w3.org/2000/svg"
    width="32.841"
    height="27.305"
    viewBox="0 0 32.841 27.305"
  >
    <path
      id="Pfad_358"
      data-name="Pfad 358"
      d="M19.184,20.837H1.317A1.318,1.318,0,0,1,0,19.522V3.665A1.318,1.318,0,0,1,1.317,2.35H19.184A1.317,1.317,0,0,1,20.5,3.665V19.522a1.317,1.317,0,0,1-1.316,1.315M1.577,19.262H18.923V3.925H1.577Z"
      transform="translate(0 6.467)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_359"
      data-name="Pfad 359"
      d="M4.379,0H9.645a.513.513,0,0,1,.52.5V8.946a.523.523,0,0,1-.889.356l-1.9-2.778a.535.535,0,0,0-.737,0L4.748,9.3a.524.524,0,0,1-.89-.356V.5A.514.514,0,0,1,4.379,0"
      transform="translate(0.198 6.35)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_360"
      data-name="Pfad 360"
      d="M15.359,12.325H5.9a.788.788,0,1,1,0-1.575h9.462a.788.788,0,1,1,0,1.575"
      transform="translate(0.262 6.628)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_361"
      data-name="Pfad 361"
      d="M15.359,12.325H5.9a.788.788,0,1,1,0-1.575h9.462a.788.788,0,1,1,0,1.575"
      transform="translate(0.262 10.828)"
      :class="fillClass + ' transition-fill'"
    />
    <g id="Exit" transform="translate(26.49) rotate(45)">
      <rect
        id="Rechteck_973"
        data-name="Rechteck 973"
        width="1.089"
        height="11.613"
        rx="0.5"
        transform="translate(0 0.77) rotate(-45)"
        :class="fillClass + ' transition-fill'"
      />
      <rect
        id="Rechteck_974"
        data-name="Rechteck 974"
        width="1.089"
        height="11.613"
        rx="0.5"
        transform="translate(8.212 0) rotate(45)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>