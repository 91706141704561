<template>
    <div
        class="relative  max-w-full"
        @click="state.isActive = !state.isActive"
        ref="dropdown"
        :class="isSmall ? 'h-8' : 'h-11'"
    >
        <div
            class="relativ w-full h-full transition-all border rounded-xl cursor-pointer"
            :class="[
                isSmall ? 'border-none' : '',
                state.isActive
                    ? `border-${activeColor}`
                    : `border-${defaultColor}`,
                'hover:border-' + activeColor,
                'hover-' + activeColor,
            ]"
        >
            <input
                ref="input"
                :title="acitveItemsString"
                disabled
                class="w-full pr-7 select-none transition-colors border-none pl-4 h-full cursor-pointer overflow-hidden overflow-ellipsis"
                :name="name"
                :class="[
                    state.isActive || acitveItemsString.length > 0
                        ? `text-black`
                        : `text-${defaultColor}`,
                    !inputDisabled ? 'bg-transparent' : '',
                ]"
                :id="id"
                :value="acitveItemsString"
            />
            <label
                :for="id"
                class="absolute transition-all cursor-pointer left-3 px-1 overflow-hidden whitespace-nowrap overflow-ellipsis"
                :style="'max-width:calc(100% - 32px)'"
                :class="[
                    isSmall
                        ? acitveItemsString.length > 0
                            ? 'opacity-0'
                            : 'top-1 opacity-100'
                        : acitveItemsString.length > 0
                        ? '-top-3 text-sm'
                        : 'top-2 text-md',
                    ,
                    `text-${activeColor}`,
                    labelClass,
                ]"
                >{{ label }}</label
            >
            <arrow-icon
                class="absolute right-3  transition-all "
                :class="[
                    isSmall ? 'top-4' : 'top-5',
                    { 'trans-r-180': state.isActive },
                ]"
                :fillClass="
                    state.isActive || acitveItemsString.length > 0
                        ? `fill-${activeColor}`
                        : `fill-${defaultColor}`
                "
            />
            <error-message-button
                class="absolute top-3"
                style="right: 9px"
                v-if="errorMessage"
                :message="errorMessage"
            />
        </div>
        <height-transition>
            <div
                v-if="!inputDisabled"
                class="transition-all absolute flex flex-col left-0 top-12 py-1 w-full z-50 rounded-xl overflow-hidden"
                :class="[
                    state.isActive
                        ? 'border active max-h-64 ' + background
                        : 'max-h-0',
                    'border-' + activeColor,
                ]"
            >
                <div
                    class="w-full px-1 h-16 pt-4"
                    v-if="isSearch"
                    @click.stop=""
                >
                    <input-text
                        :id="'filter-text-' + id"
                        :label="$t('filter')"
                        v-model="state.filterText"
                        :labelClass="background"
                        class="w-full"
                    />
                </div>
                <div
                    class="scrollbar overflow-auto z-50"
                    :class="isSearch ? 'max-h-48' : 'max-h-64'"
                >
                    <div
                        v-for="(option, index) in filteredOptions"
                        :key="index"
                        class=" flex flex-row pl-1 pr-3 cursor-pointer justify-between items-center transition-colors h-10 hover:bg-opacity-10"
                        :class="'hover:bg-' + activeColor"
                        @click.stop="
                            $emit('changeItem', option, index);
                            REMOVE_ERROR_ID();
                            closeOnSelect ? (state.isActive = false) : '';
                        "
                    >
                        <div
                            class="flex flex-col p-1 w-full"
                            v-if="nameColumn"
                            :title="option[nameColumn]"
                            :style="
                                activeItems.includes(option[nameColumn])
                                    ? 'max-width:calc(100% - 16px)'
                                    : 'max-width:calc(100%)'
                            "
                        >
                            <h3
                                class="text-left select-none overflow-hidden whitespace-nowrap overflow-ellipsis w-full"
                            >
                                {{ option[nameColumn] }}
                            </h3>
                        </div>
                        <div
                            v-if="
                                nameColumn &&
                                    activeItems.filter(
                                        (e) =>
                                            e &&
                                            e[nameColumn] &&
                                            e[nameColumn] == option[nameColumn]
                                    ).length > 0
                            "
                        >
                            <checked-icon :fillClass="'fill-primary'" />
                        </div>

                        <div
                            class="flex flex-col p-1 w-full"
                            v-if="!nameColumn"
                            :title="option"
                            :style="
                                activeItems.includes(option)
                                    ? 'max-width:calc(100% - 16px)'
                                    : 'max-width:calc(100%)'
                            "
                        >
                            <h3
                                class="text-left select-none overflow-hidden whitespace-nowrap overflow-ellipsis w-full"
                            >
                                {{ option }}
                            </h3>
                        </div>
                        <div
                            v-if="!nameColumn && activeItems.includes(option)"
                            class="flex-shrink-0"
                        >
                            <checked-icon :fillClass="'fill-primary'" />
                        </div>
                    </div>
                </div></div
        ></height-transition>
    </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { clickOutside } from "../../common/customDirectives";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import ErrorMessageButton from "../global/ErrorMessageButton.vue";
import HeightTransition from "../transitions/HeightTransition.vue";
import InputText from "./InputText.vue";

export default defineComponent({
    name: "InputSelect",
    components: { InputText, HeightTransition, ErrorMessageButton },

    props: {
        id: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
        },
        activeItems: {
            type: Array,
            required: true,
        },
        nameColumn: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        labelClass: {
            type: String,
            required: false,
        },
        isSmall: {
            type: Boolean,
            default: false,
        },
        isSearch: {
            type: Boolean,
            default: false,
        },
        background: {
            type: String,
            required: false,
        },
        defaultColor: {
            type: String,
            required: true,
        },
        activeColor: {
            type: String,
            required: true,
        },
        inputDisabled: {
            type: Boolean,
            required: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["changeItem"],
    setup(props) {
        const store = useStore();
        const dropdown = ref();
        const i18n = useI18n();
        const state = reactive({
            filterText: "",

            isActive: false,
        });
        function closeDropdown() {
            state.isActive = false;
        }
        function close() {
            state.isActive = false;
        }
        function REMOVE_ERROR_ID() {
            if (errorMessage.value) {
                store.commit(
                    `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_ERROR_ID}`,
                    props.name
                );
            }
        }
        /**
         * Mounted
         */
        onMounted(() => {
            clickOutside(dropdown.value, closeDropdown);
        });
        /**
         *  Computed
         */
        const acitveItemsString = computed(() => {
            if (!props.nameColumn) {
                return props.activeItems.join(", ");
            } else {
                let myItems = [];
                props.activeItems.forEach((currentItem) => {
                    if (currentItem && currentItem[props.nameColumn]) {
                        myItems.push(currentItem[props.nameColumn]);
                    }
                });
                return myItems.join(",");
            }
        });
        const errorMessage = computed(() => {
            const errorIds =
                store.getters[
                    `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_ERROR_IDS}`
                ];
            let error = errorIds[props.name];
            if (error) {
                return i18n.t(props.name) + " wird benötigt.";
            }
            return error;
        });
        const filteredOptions = computed(() => {
            let filteredSearch = state.filterText.toLowerCase();
            return props.options.filter((option) => {
                if (!props.isSearch) {
                    return true;
                }
                if (props.nameColumn) {
                    return (
                        option[props.nameColumn]
                            .toLowerCase()
                            .indexOf(filteredSearch) > -1
                    );
                } else {
                    return option.toLowerCase().indexOf(filteredSearch) > -1;
                }
            });
        });
        return {
            state,
            dropdown,
            close,
            acitveItemsString,
            filteredOptions,
            errorMessage,
            REMOVE_ERROR_ID,
        };
    },
});
</script>
