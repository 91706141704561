import { SETTINGS_TYPE } from "../types";

const state = {};

const getters = {};

const actions = {
    async [SETTINGS_TYPE.FETCH_SETTINGS]() {
        await new Promise((r) => setTimeout(r, 0));
        return;
    },
};

const mutations = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
