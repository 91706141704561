<template>
  <svg
    id="Send"
    xmlns="http://www.w3.org/2000/svg"
    width="17.105"
    height="18.06"
    viewBox="0 0 17.105 18.06"
  >
    <path
      id="Pfad_414"
      data-name="Pfad 414"
      d="M341.982,136.374a1.307,1.307,0,0,1-1.231-1.735l2.524-7.246-2.556-7.344a1.3,1.3,0,0,1,1.846-1.58l14.495,7.74a1.3,1.3,0,0,1,0,2.3L342.6,136.219A1.305,1.305,0,0,1,341.982,136.374Zm.186-1.243h0Zm.132-15.1,2.414,6.936a1.3,1.3,0,0,1,0,.86l-2.38,6.836,13.7-7.3Zm1,7.43h0Z"
      transform="translate(-340.645 -118.314)"
      :class="fillClass + ' transition-fill'"
    />
    <rect
      id="Rechteck_1037"
      data-name="Rechteck 1037"
      width="12.97"
      height="1.5"
      transform="translate(3.385 8.28)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "SendIcon",
    props: {
        fillClass: {
            type: String,
            required: true,
        },
    },
});
</script>