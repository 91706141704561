import axios from "axios";
import { ENV } from "../../env";
import store from "../store";
import router from "../router";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../store/types";
import { getData } from "./dataHelper";

const ApiService = {
    init() {
        axios.defaults.baseURL = ENV.API_URL;

        this.setHeader();
        axios.interceptors.response.use(
            (response) => response,
            (error) => this.errorHandler(error)
        );
    },

    setHeader() {
        axios.defaults.withCredentials = true;
        axios.defaults.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        };
    },
    errorHandler(error) {
        if (getData(error, "response.status") == 401) {
            console.log("NOT AUTHORIZED");
            store.dispatch(
                `${STORE_TYPES.AUTH}/${AUTH_TYPES.LOGOUT}`);
            router.push({ name: "Login" });
        } else if (getData(error, "response.status") == 404) {
            console.log("NOT FOUND");
        } else if (getData(error, "response.status") == 422){
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_ERROR_ID_OBJECT}`,
                getData(error, "response.data.errors")
            );
        }
        throw error;
    },
    query(resource, params) {
        return axios.get(resource, params).catch((error) => {
            return error;
        });
    },
     getCookies() {

        return axios.get(ENV.COOKIES).catch((error) => {
            return error;
        });
    },
    get(resource, slug = "") {
        return axios.get(`${resource}/${slug}`).catch((error) => {
            return error;
        });
    },

    async post(resource, params, config) {
        return await axios
            .post(`${resource}`, params, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    },
    async put(resource, params, config) {
        return await axios
            .put(`${resource}`, params, config)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    },

    update(resource, slug, params) {
        // TODO: Data to FormData
        return axios
            .put(`${resource}/${slug}`, params)
            .then((response) => {
                return response;
            })
            .catch(() => {
                return;
            });
    },
    updateSingle(resource, params) {
        // TODO: Data to FormData
        return axios
            .put(`${resource}`, params)
            .then((response) => {
                return response;
            })
            .catch(() => {
                return;
            });
    },

    destroy(resource, slug,) {
        return axios.delete(`${resource}/${slug}`).catch((error) => {
            return error;
        });
    },
    delete(resource) {
        return axios.delete(resource).catch((error) => {
            return error;
        });
    },
};

export default ApiService;
