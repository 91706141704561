<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.841"
    height="21.169"
    viewBox="0 0 29.841 21.169"
  >
    <g
      id="Gruppe_312"
      data-name="Gruppe 312"
      transform="translate(-1741 -21.896)"
    >
      <g id="Human" transform="translate(1741 23)">
        <path
          id="Pfad_263"
          data-name="Pfad 263"
          d="M120.386,82.75c-4.975,0-8.872-2.368-8.872-5.391s3.9-5.392,8.872-5.392,8.872,2.368,8.872,5.392S125.361,82.75,120.386,82.75Zm0-9.283c-4,0-7.372,1.783-7.372,3.892s3.376,3.891,7.372,3.891,7.372-1.782,7.372-3.891S124.382,73.467,120.386,73.467Z"
          transform="translate(-111.514 -62.685)"
          :class="fillClass + ' transition-fill'"
        />
        <path
          id="Pfad_264"
          data-name="Pfad 264"
          d="M120.386,73.455A5.419,5.419,0,1,1,125.2,68.07,5.128,5.128,0,0,1,120.386,73.455Zm0-9.27A3.935,3.935,0,1,0,123.7,68.07,3.636,3.636,0,0,0,120.386,64.185Z"
          transform="translate(-111.514 -62.685)"
          :class="fillClass + ' transition-fill'"
        />
      </g>
      <g id="Exit" transform="translate(1764.49 21.896) rotate(45)">
        <rect
          id="Rechteck_973"
          data-name="Rechteck 973"
          width="1.089"
          height="11.613"
          rx="0.5"
          transform="translate(0 0.77) rotate(-45)"
          :class="fillClass + ' transition-fill'"
        />
        <rect
          id="Rechteck_974"
          data-name="Rechteck 974"
          width="1.089"
          height="11.613"
          rx="0.5"
          transform="translate(8.212 0) rotate(45)"
          :class="fillClass + ' transition-fill'"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdduserIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>