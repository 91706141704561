export function clickOutside(HTMLElement, caseOutsideFunction) {
    document.body.addEventListener("click", (event) => {
        if (HTMLElement == event.target || HTMLElement.contains(event.target)) {
            return true;
        } else {
            caseOutsideFunction();
            return false;
        }
    });
}
