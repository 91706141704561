<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.234"
    height="14.5"
    viewBox="0 0 7.234 14.5"
  >
    <g
      id="Arrow_left"
      data-name="Arrow left"
      transform="translate(7.234 14.5) rotate(180)"
    >
      <path
        id="Pfad_307"
        data-name="Pfad 307"
        d="M274,105.75a.747.747,0,0,1-.555-.246l-5.625-6.19a1.24,1.24,0,0,1,0-1.628l5.625-6.19a.75.75,0,1,1,1.11,1.008l-5.448,6,5.448,6A.75.75,0,0,1,274,105.75Z"
        transform="translate(-267.516 -91.25)"
        :class="fillClass + ' transition-fill'"
      />
    </g>
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ArrowRightIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>