import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import { USER_TYPES } from "../types";

const state = {
    users: [],
    permissions: [],
    users_planning: [],
};
const getters = {
    [USER_TYPES.GET_USERS]: (state) => { return state.users },
    [USER_TYPES.GET_USERS_PLANNING]: (state) => { return state.users_planning },
    [USER_TYPES.GET_USER_PERMISSIONS]: (state) => { return state.permissions }
};
const actions = {
    async [USER_TYPES.FETCH_USERS]({ commit }) {

        const response = await ApiService.query("users");

        const users = getData(response, 'data')
        if (users) {
            commit(USER_TYPES.SET_USERS, users)
        }
    },
    async [USER_TYPES.FETCH_USERS_PLANNING]({ commit },{year}) {
        const response = await ApiService.query("planning?year=" + year);

        const users = getData(response, 'data')
        if (users) {
            commit(USER_TYPES.SET_USERS_PLANNING, users)
        }
    },
    async [USER_TYPES.CREATE_USER]({ commit }, { inputUser }) {
        const response = await ApiService.post("users", inputUser);
        const user = getData(response, 'data')
        if (user) {
            commit(USER_TYPES.ADD_USER, user)
        }
        return user;
    },
    async [USER_TYPES.FETCH_USER_PERMISSIONS]({ commit }) {
        const response = await ApiService.query("permissions");
        const perm = getData(response, 'data')
        if (perm) {
            commit(USER_TYPES.SET_USER_PERMISSIONS, perm)
        }
    },
    async [USER_TYPES.UPDATE_USER]({ dispatch }, { inputUser }) {
        const response = await ApiService.update("users", inputUser.id, inputUser);
        dispatch(USER_TYPES.FETCH_USERS);
        return response;
    },

    async [USER_TYPES.UPDATE_USER_PICTURE]({ dispatch }, { inputUser }) {
        const response = await ApiService.post("users/" + inputUser.id + "/image", inputUser.picture);
        dispatch(USER_TYPES.FETCH_USERS);
        return response;
    },
    async [USER_TYPES.DELETE_USER_PICTURE]({ dispatch }, { inputUser }) {
        const response = await ApiService.delete("users/" + inputUser.id + "/image");
        dispatch(USER_TYPES.FETCH_USERS);
        return response;
    },
    async [USER_TYPES.DELETE_USER]({ dispatch }, { userID }) {
        await ApiService.destroy("users", userID)
        dispatch(USER_TYPES.FETCH_USERS);
    }
};
const mutations = {
    [USER_TYPES.SET_USERS]: (state, array) =>
        (state.users = array),
    [USER_TYPES.SET_USERS_PLANNING]: (state, array) =>
        (state.users_planning = array),
    [USER_TYPES.ADD_USER]: (state, object) => {
        state.users.push(object);
    },
    [USER_TYPES.SET_USER_PERMISSIONS]: (state, array) => {
        state.permissions = array;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
