import ApiService from "../../common/apiService";
import { getData } from "../../common/dataHelper";
import { CALENDAR_TYPES } from "../types";

const state = {
    users: [],
    permissions: [],
};
const getters = {
    [CALENDAR_TYPES.GET_CALENDAR_ENTRIES]: (state) => { return state.users },
};
const actions = {
    async [CALENDAR_TYPES.FETCH_CALENDAR_ENTRIES]({ commit }) {

        const response = await ApiService.query("calendarEntries");

        const entries = getData(response, 'data')
        if (entries) {
            commit(CALENDAR_TYPES.SET_CALENDAR_ENTRIES, entries)
        }
    },
    async [CALENDAR_TYPES.UPDATE_CALENDAR_ENTRIE]({dispatch}, {inputEntrie}) {
        console.log(inputEntrie);
        const response = await ApiService.update("calendarEntries", inputEntrie.id, inputEntrie);
        dispatch(CALENDAR_TYPES.FETCH_CALENDAR_ENTRIES);
        return response;
    },
    async [CALENDAR_TYPES.CREATE_CALENDAR_ENTRIE]({dispatch}, {inputEntrie}) {
        const response = await ApiService.post("calendarEntries", inputEntrie);
        const entrie = getData(response, 'data')
        dispatch(CALENDAR_TYPES.FETCH_CALENDAR_ENTRIES);
        return entrie;
    },
    async [CALENDAR_TYPES.DELETE_CALENDAR_ENTRIE]({dispatch}, {inputEntrie}) {
        const response = await ApiService.destroy("calendarEntries", inputEntrie.id,inputEntrie);
        dispatch(CALENDAR_TYPES.FETCH_CALENDAR_ENTRIES);
        return response;
    }
};
const mutations = {
    [CALENDAR_TYPES.SET_CALENDAR_ENTRIES]: (state, array) =>
        (state.users = array),
};

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
};
