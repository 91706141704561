<template>
  <svg
    id="Project"
    xmlns="http://www.w3.org/2000/svg"
    width="20.5"
    height="20.955"
    viewBox="0 0 20.5 20.955"
  >
    <path
      id="Pfad_358"
      data-name="Pfad 358"
      d="M19.184,20.837H1.317A1.318,1.318,0,0,1,0,19.522V3.665A1.318,1.318,0,0,1,1.317,2.35H19.184A1.317,1.317,0,0,1,20.5,3.665V19.522a1.317,1.317,0,0,1-1.316,1.315M1.577,19.262H18.923V3.925H1.577Z"
      transform="translate(0 0.118)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_359"
      data-name="Pfad 359"
      d="M4.379,0H9.645a.513.513,0,0,1,.52.5V8.946a.523.523,0,0,1-.889.356l-1.9-2.778a.535.535,0,0,0-.737,0L4.748,9.3a.524.524,0,0,1-.89-.356V.5A.514.514,0,0,1,4.379,0"
      transform="translate(0.198)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_360"
      data-name="Pfad 360"
      d="M15.359,12.325H5.9a.788.788,0,1,1,0-1.575h9.462a.788.788,0,1,1,0,1.575"
      transform="translate(0.262 0.278)"
      :class="fillClass + ' transition-fill'"
    />
    <path
      id="Pfad_361"
      data-name="Pfad 361"
      d="M15.359,12.325H5.9a.788.788,0,1,1,0-1.575h9.462a.788.788,0,1,1,0,1.575"
      transform="translate(0.262 4.479)"
      :class="fillClass + ' transition-fill'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>