import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { AUTH_TYPES, STORE_TYPES } from "../store/types";

const routes = [
    {
        path: "/home",
        name: "Home",
        component: () => import("../views/dashboard/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: false,
        },
    },
    {
        path: "/",
        name: "Dashboard",
        component: () => import("../views/dashboard/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: false,
        },
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/settings/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: false,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/auth/Login.vue"),
        meta: {
            isLoggedIn: false,
            requiresPermission: false,
        },
    },
    {
        path: "/resetpassword",
        name: "ResetPassword",
        component: () => import("../views/auth/ResetPassword.vue"),
        meta: {
            isLoggedIn: false,
            requiresPermission: false,
        },
    },
    {
        path: "/enternewpassword",
        name: "EnterNewPassword",
        component: () => import("../views/auth/EnterNewPassword.vue"),
        meta: {
            isLoggedIn: false,
            requiresPermission: false,
        },
    },
    {
        path: "/two-factor",
        name: "TwoFactorCode",
        component: () => import("../views/auth/TwoFactorCode.vue"),
        meta: {
            isLoggedIn: false,
            requiresPermission: false,
        },
    },
    {
        path: "/personal",
        name: "Personal",
        component: () => import("../views/personal/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: 'personnel_management',
        },
    },
    {
        path: "/projekte",
        name: "Projekte",
        component: () => import("../views/projects/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: false,
        },
    },
    {
        path: "/planung",
        name: "Planung",
        component: () => import("../views/planning/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: false,
        },
    },
    {
        path: "/kalender",
        name: "Kalender",
        component: () => import("../views/calender/Index.vue"),
        meta: {
            requiresAdmin: true,
            isLoggedIn: true,
            requiresPermission: 'calendar_management',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach(async (to, from, next) => {
    // const isAdmin = true;
    const isLoggedIn =
        store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.IS_LOGGED_IN}`];
    
    if(!from.name && isLoggedIn)
    {
        await store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_USER}`);
    }
    
    const userPermissions = await store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`].permissions;
    console.log(userPermissions);
    // const permissions = [];
    // if(user)
    // {
    //     if(user.permissions.length > 0)
    //     user.permissions.forEach(element => {
    //         permissions.push(element)
    //     });
    // }
    // const routeRequiresAdmin = to.matched.some(
    //     (record) => record.meta.requiresAdmin
    // );
    const routeRequiresLogIn = to.matched.some(
        (record) => record.meta.isLoggedIn
    );
    let hasPermission = true;
    if(userPermissions && to.meta.requiresPermission)
    {
         hasPermission = userPermissions.find((o) =>{ if(o.name == to.meta.requiresPermission) { return true}})
    }

    if (to.name == "TEst") {
        console.log("test");
    } else if (!isLoggedIn && routeRequiresLogIn) {
        next({ name: "Login" });
        return;
    } else if (isLoggedIn && !routeRequiresLogIn) {
        next({ name: "Dashboard" });
    }
    if(!hasPermission)
    {
        next({ name: "Dashboard" });
    }
    next();
});
export default router;
