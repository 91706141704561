<template>
  <svg
    id="Communication"
    xmlns="http://www.w3.org/2000/svg"
    width="15.2"
    height="17.109"
    viewBox="0 0 15.2 17.109"
  >
    <path
      id="Pfad_319"
      data-name="Pfad 319"
      d="M304.194,87.759a1.3,1.3,0,0,0,.587-.141.694.694,0,0,0,.12-.076l4.248-3.339,4.149.015h0a2,2,0,0,0,1.976-2.011V72.664A2,2,0,0,0,313.3,70.65H302.052a2,2,0,0,0-1.977,2.014v9.541c0,.9.891,1.19,1.481,1.379.135.043.324.1.439.155l.972,3.165a.764.764,0,0,0,.043.109A1.321,1.321,0,0,0,304.194,87.759Zm9.1-5.023h-4.234a1.305,1.305,0,0,0-.594.145.687.687,0,0,0-.115.074l-4.089,3.213-.869-2.83c-.087-.745-.873-1-1.4-1.168-.134-.043-.336-.107-.464-.165V72.664a.527.527,0,0,1,.522-.532H313.3a.527.527,0,0,1,.522.532v9.541a.527.527,0,0,1-.521.53Zm-10.621.741h0Z"
      transform="translate(-300.075 -70.65)"
      :class="fillClass + ' transition-fill-opacity'"
    />
  </svg>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "CommentIcon",
  props: {
    fillClass: {
      type: String,
      required: true,
    },
  },
});
</script>